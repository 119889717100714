import React, { Component } from 'react';
// import logo from '../../assets/images/gartner-logo.svg';
import { Logo } from 'talentneuron---ui-components-library';
import { Link } from 'react-router-dom';
export default class UnAuthorizedAccess extends Component {
	render(props) {
		return (
			<div id="divResourceNotFound" >
				<div className="tnp-nav-top">
					<div className="tnp-container-fluid"  >
						<div className="tnp-logo-con">
							<Link to="/">
								<img className="tnp-nav-top-logo" alt="logo" src={Logo} />
								<span className="tnp-nav-logo-text">
									TalentNeuron
								</span>
							</Link>
						</div>
					</div>
				</div>
				<div className="notfound">
					<span className="fa-stack fa-4x">
						<i className="fa fa-stack-2x fa-circle"> </i>
						<i className="fa fa-stack-1x fa-unlink fa-inverse"> </i>
					</span>
					<div className="bold notfoundtext">
						404 Not found
			</div>
					<div>
						<span>
							Uh oh, we can't seem to find the page you're looking for.
                 <br></br>
							Let's get you back on track.
                </span>
					</div>
					<br></br>
					<div className="allignmiddle">
						<div className="btn-group ">
							<Link type="button" className="btn btn-primary" to="/">Return to Homepage</Link>
							<button type="button" className="btn btn-primary" onClick={this.props.history.goBack}>Return to previous page</button>
						</div>
					</div>

				</div>
			</div>
		)
	}
}