import React, { Component } from "react";
import PropTypes from "prop-types";
import './style.css'

const PDFCoverPage = ({jobroledescription, skills, intakeinformation, currency, intl}) =>{
    
    if(!intakeinformation){
        return null
    }
    
    let CRDetails = intakeinformation
    let dt = new Date(jobroledescription[0].publishedDate).toDateString('MM-DD-YYYY').split(' ');
    return (
        <div>
            <div className="rep-main-heading">{intl.formatMessage({ id: "results_for" })}</div>
            <div className="rep-role-heading" >
                <div style={{float:"left", marginRight:"50px"}}>
                    <div className="rep-role-name">{CRDetails.rolename}</div>
                    <div className="rep-location">{CRDetails.location} | {dt[1] + ' ' + dt[2] + '. ' + dt[3]}</div>
                </div>
                <div>
                    <div className="rep-role-name">{intl.formatMessage({ id: "currency" })}</div>
                    <div className="rep-location">{currency.name}</div>
                </div>
            </div>
            <div className="rep-intake-heading">{intl.formatMessage({ id: "intake_information" })}</div>
            <SkillsList skills={skills.breakdown} intl={intl} />
            <div className="rep-skills-heading"> {intl.formatMessage({ id: "description" })}:</div>
            <div className="rep-content">{jobroledescription[0].roleDescription}</div>

        </div>
    );
    
}


export default PDFCoverPage;


const SkillsList = (props) => {
    return(
        <div>
            <div className="rep-skills-heading"> {props.intl.formatMessage({ id: "top_skills" })}:</div>
            <ul>
                {props.skills.map((val, idx) => <li key={idx} className="rep-content">{val.name}</li>)}
            </ul>
        </div>
    )
}