import React, { Component } from 'react'
import {Loader} from 'tn-components-library';
import './styles.css';

export default class Loading extends Component {
  constructor() {
    super();
    this.state = {
      show: true
    }
  }
  show = () => {
    this.setState({ show: true })
  }

  hide = () => {
    this.setState({ show: false })
  }
  render() {
    return (
      <div className="loader">
        <Loader
          type = 'dark'/>
      </div>
    )
  }
}

