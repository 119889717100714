import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'tn-components-library';
import './style.css';

export default class PopOver extends Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            showPopover: this.props.showPopover,
            btnType: "disabled",
            fromLeft: '',
            reportName: '',
			reportDescription: '',
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.showPopover !== this.state.showPopover) {
            this.setState({ showPopover: nextProps.showPopover, btnType: "disabled", reportName: "", reportDescription: "" });
        }
    }

    

    hide() {
        this.setState ({
            showPopover: false,
            reportName: "",
            reportDescription: ""
        });
        this.props.togglePopover();
    }

    generate() {
        
        let title = document.getElementById("reportName").value
        let desc = document.getElementById("reportDesc").value
        if(title.trim() == ""){
            return;
        }
        this.props.downloadPdf(title, desc)
        
        this.setState ({
            showPopover: false,
            reportName: "",
            reportDescription: ""
        })
        this.props.togglePopover();
    }

    onKeyUp(e){
        let name = e.target.value;
        let value = e.target.value.replace(/\s/g, '')
        if (value.length > 0 )
        {
            this.setState ({
                reportName: name,
                btnType: "primary"
            })
        } else {
            this.setState ({
                btnType: "disabled"
            })
        }
    }
    onKeyUpDesc(e){
        let name = e.target.value;
        this.setState ({
            reportDescription: name,
        })
    }

    render() {
        let popOverElement = document.querySelector(".pdf-download");
        let leftPosition = popOverElement ? (popOverElement.offsetLeft - (316/2) - 2.8 ) : 0
        let fromLeft = {
            left: leftPosition + 'px'
        }
        return (this.state.showPopover &&
            <div className={'popover popover-arrow ' + this.props.style} style={fromLeft}>
                <div className="popover-body">
                    <div>
                        <div className="popover-label">{this.context.intl.formatMessage({ 'id': 'report-name' })}</div>
                        <input id="reportName" placeholder="Write name" maxLength="30" onKeyUp={this.onKeyUp.bind(this)}/>
                        <div className="popover-limit margin-bottom-15">{this.context.intl.formatMessage({ 'id': 'character-limit' })} 
                            : <span className="popover-limit-char">{(30 - this.state.reportName.length).toString()}</span>
                        </div>
                        <div className="popover-label">
                            {this.context.intl.formatMessage({ 'id': 'report-description' })} - 
                            <i>{this.context.intl.formatMessage({ 'id': 'optional' })}</i>
                        </div>
                        <textarea id="reportDesc" placeholder="Write description" maxLength="100" className="textarea" onKeyUp={this.onKeyUpDesc.bind(this)} />
                        <div className="popover-limit">{this.context.intl.formatMessage({ 'id': 'character-limit' })} 
                            : <span className="popover-limit-char"> {(100 - this.state.reportDescription.length).toString()}</span>
                        </div>
                    </div>
                    <div className="pull-right margin-top-15">
                        <div className='btn-cancel'>
                        <Button 
                            category="primary-outline" 
                            outline 
                            label={this.context.intl.formatMessage({ 'id': 'cancel' })} 
                            onClick={this.hide.bind(this)} />
                        </div>
                        <Button category={this.state.btnType} 
                            outline = {this.state.btnType == 'primary' ? true : false}
                            label={this.context.intl.formatMessage({ 'id': 'generate' })} 
                            onClick={this.generate.bind(this)} />
                    </div>
                    <div className="clear"></div>
                </div>
            </div>
        );
    }
}

PopOver.contextTypes = {
    intl: PropTypes.object.isRequired
}