import React, { Component } from "react";
import PropTypes from 'prop-types'
import {HxCodeToDangerousDOMElelemt} from '../../utils';
import './style.css'


class DiversityBar extends Component {

    borderColor = (value) => {
        
        value = 100 - value //bar is unfavour to favor
        if (value == 0) {
            return "#416428"
        }
        else if (value >= 1 && value <= 21) {
            return "#6a9d0c"
        }
        else if (value >= 22 && value <= 55) {
            return "#e9ae2a"
        }
        else if (value >= 56 && value <= 83) {
            return "#d36b00"
        }
        else {
            return "#94332d"
        }
    }
	render() {
        let barHeight = this.props.height +"px";
        let leftText = this.props.leftText;
        let rightText = this.props.rightText;
        let toolTipText = this.props.toolTipText;
        let value = this.props.value;
        let borderColor = this.borderColor(value)
		return (
            <div>
                <DiverseTooltipBox value={value} label={toolTipText} borderColor={borderColor} /> 
                <DiverseTooltipArrow value={value} borderColor={borderColor} />
                <div className="main-bar" style={{height:barHeight, clear:"both"}}></div>
                <div className="marker-text">
                    <div style={{float:"left"}}>{leftText}</div>
                    <div style={{float:"right"}}>{rightText}</div>
                </div>
            </div>
        )
	}
}

DiversityBar.propTypes = {
    barHeight: PropTypes.number,
    leftText: PropTypes.string,
    rightText : PropTypes.string
}

DiversityBar.defaultProps = {
    barHeight : 15,
    leftText: "",
    rightText : ""
};

export default DiversityBar


const  DiverseTooltipArrow = (props) =>{

    let leftMargin = props.value+"%";
    let floating = "left";

    if(props.value >= 4 && props.value <= 97){
        leftMargin = "calc("+props.value+"% - 10px)"
    }
    else if(props.value >= 98){
        floating = "right";
        leftMargin = "98%"
    }
    
    const tooltipArrowStyle = {
        marginLeft : leftMargin, 
        float : floating,
        clear:"both", 
        marginBottom:"3px"  
    };
    const tooltipArrowIconStyle = {
        color:props.borderColor, 
        fontSize:"20px", 
        position :"relative",
        top : "9px"
    };
    const whiteTip = {
        color:"#ffffff", 
        fontSize:"14px", 
        position :"absolute",
        top : "0px",
        left: "3px"
    };

    return (
        <div className="diverse-tooltipBox-arrow" style={tooltipArrowStyle}>
            <span style={tooltipArrowIconStyle} className="ga  ga-caret-down">
                <span style={whiteTip} className="ga ga-caret-down">
                </span>
            </span>
        </div>
    );
};
const  DiverseTooltipBox = (props) =>{

    let leftMargin = props.value+"%";
    let floating = "left";
    
    if (props.value <= 10){
        leftMargin = "0%";
        floating = "left"
    }
    else if(props.value >=11 && props.value <= 87){
        leftMargin = "calc("+props.value+"% - 54px)"
    }
    else if (props.value >= 88){
        leftMargin = "0%";
        floating = "right"
    }
    const tooltipBoxStyle = {
        borderColor:props.borderColor,
        float:floating,
        marginLeft : leftMargin 
    };

    return (
        <div className="diverse-tooltipBox" style={tooltipBoxStyle}>
            <div className="diverse-text">{props.label}</div>
            <div className="diverse-value">{props.value}%</div>
        </div>
    );
};