import Config from '../profiles';
import http from './http';

export const getPersonaResponsibilities = () => {
    return http.get(Config.getAdminServiceURL +`/v1/userprofiles/category`)
}

export const updatePersona = (param) => {
    return http.patch(Config.getAdminServiceURL +`/v1/userprofiles/update/persona`, param)
}

export const updateResponsibilities = (param) => {
    return http.patch(Config.getAdminServiceURL +`/v1/userprofiles/update/responsibilities`, param)
}
