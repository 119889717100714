import React, {Component} from 'react'
import { withRouter } from 'react-router-dom'
import {updatePersona, updateResponsibilities} from "../../http/userProfile";
import {UserProfileModal} from 'talentneuron---ui-components-library';

class UserProfile  extends  Component{
    constructor(props) {
        super(props);
        this.state ={
            submitted: false
        }
        this.submit=this.submit.bind(this);
    }


    submit(selection) {
        Promise.all([
            selection.defaultpersona && selection.defaultpersona[0] && updatePersona({personName:selection.defaultpersona[0].name, personaId:selection.defaultpersona[0].id}),
            selection.responsabilities && selection.responsabilities[0] && updateResponsibilities({ responsabilitiesIds:selection.responsabilities.map(el=>{return el.id}).join(",")})
        ])
        .then(responses => { 
            this.setState({
                submitted: true, 
            })
        })
        .catch(error => {
            console.log(error);
        });

    }


    render (){
        
        return (
            <UserProfileModal
                userId = {window.customrole.UserContext.geminiUserId}
                userPersonaQuestions={this.props.userPersonaQuestions}
                userResponsabilitiesQuestions={this.props.userResponsabilitiesQuestions}
                doSubmit={this.submit}
                submitted={this.state.submitted}
            />
        )    
    }

}

export default withRouter(UserProfile);
