import React, { Component } from "react";
import Widget from "../../component/Widget";
import PropTypes from 'prop-types';
import './style.css'

import DiversityBar from "../../component/DiversityBar"

class GenderDiversity extends Component {
    constructor(props){
        super(props)
    }
    render() {
        if(!this.props.data){
            return <Widget {...this.props} />
        }
        const {countryCode} = this.props;
        let cssClass;
        if(this.props.isReport && countryCode !== "US"){
            cssClass =  "" ;
        } else {
            cssClass = ((countryCode && countryCode !== "US")? "col-xs-6 col-xs-offset-3" : "" );
        }
        
        return (
            <Widget  {...this.props} showTitle={(countryCode !== "US")}>
                <div className={"gender-diversity " + cssClass}>
                    <DiversityBar  height={15} leftText="Very unfavorable" rightText="Very favorable" value={this.props.data.femalepercent} toolTipText="Women candidates" />
                    <div className="row">
                        <div className="col-xs-6 text-center">
                            <GenderCountGraph gender={"men"} percent={this.props.data.malepercent} count={this.props.data.malecount} />
                        </div>
                        <div className="col-xs-6 text-center">
                            <GenderCountGraph gender={"women"} percent={this.props.data.femalepercent} count={this.props.data.femalecount} />
                        </div>
                    </div>
                </div>
            </Widget>
        );
    }
}

GenderDiversity.defaultProps = {
    title: "diverses_title",
}

export default GenderDiversity;

export const GenderCountGraph = (props) => {
    return(
        <div className="gender-count-graph">
            <div className="gender-percent">{props.percent}%</div>
            <div className="gender-icons">
                <IconsTable gender={props.gender} percent={props.percent}/>
            </div>
            <div className="gender-message">are filled by <span style={{fontWeight:600}}>{props.gender}</span> with a headcount of</div>
            <div className="gender-count">{props.count}</div>
        </div>
    )
}



export const IconsTable = (props) => {

    let genderIcon = props.gender == "men" ? "ga ga-zzz1d-man" : "ga ga-zzz1e-woman"
    let oppsiteIcon  = props.gender == "men" ? "ga ga-zzz1e-woman" : "ga ga-zzz1d-man"
    let percentCalculator = 0;

    function displayIcons(){
        let IconsArr = [];
        for(let i=0; i<5; i++){
            for(let j=0; j<10; j++){
                if(percentCalculator < props.percent){
                    IconsArr.push(<span className={"highlight gender-icon "+ genderIcon}></span>)
                }
                else{
                    IconsArr.push(<span className={"gender-icon "+ oppsiteIcon}></span>)
                }
                percentCalculator = percentCalculator+2
            }
            IconsArr.push(<br/>)
        }
        return IconsArr
    }

    return(
        <div className="gender-graph">
            {displayIcons(props)}
        </div>
    )
}