// React imports
import React, { Component } from "react";
import './style.css'
import PropTypes from "prop-types";
import NotEnoughData from '../../container/home/nodatacomponent/notEnoughData';
import ExpandingPanel from '../ExpandingPanel';
import scrollToComponent from 'react-scroll-to-component';


class HigherOrderDashboardWidget extends Component {


    scrollWidgetPoint(isExpanded, scrollPoint) {

        if (!isExpanded){
            setTimeout(function() {
                scrollToComponent(scrollPoint, {
                    offset: 0,
                    align: 'bottom',
                    duration: 200
                });
            }, 500);
        }
        if (isExpanded) {
            setTimeout(function() {
                scrollToComponent(scrollPoint, {
                    offset: 0,
                    align: 'bottom',
                    duration: 300
                });
            }, 500);
        }
    }

    render() {
        let intl = this.context.intl;
        const displayContent = () =>{
            if(!this.props.data || this.props.data.length===0){
                return <NotEnoughData />
            }else{
                return (this.props.children)
            }
        }

        let title = (this.props.showTitle && this.props.title) ? intl.formatMessage({ id: this.props.title }) : '';

        return (
            <div className="widget-item-upper">
                <div className="row">
                    <div className={"col-xs-12 widget-header-title"}>
                        {title}
                    </div>
                </div>
                { this.props.subTitle && <div className="row">
                    <div className={"col-xs-12 widget-header-subtitle"}>
                        {intl.formatMessage({ id: this.props.subTitle })}
                    </div>
                </div>}
                <div className="row">
                    <div className={"col-xs-12  widget-main-container"}>
                        {displayContent()}
                        {(this.props.expandable && this.props.data)&&<ExpandingPanel 
                            scrollHandler={this.scrollWidgetPoint} {...this.props}
                        />}
                    </div>
                </div>
            </div>
        );
    }
}

HigherOrderDashboardWidget.contextTypes = {
    intl: PropTypes.object.isRequired
}

HigherOrderDashboardWidget.propTypes = {
    title: PropTypes.string
};

HigherOrderDashboardWidget.defaultProps = {
    showTitle: true,
}

export default HigherOrderDashboardWidget;
