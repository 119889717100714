import React, { Component } from "react";
import Widget from "../../component/Widget";
import PieChart from "../../component/PieChart";
import PropTypes from 'prop-types';
import './style.css'

import DiversityBar from "../../component/DiversityBar"

const  getTotal = (data, mapping) =>{
    let counts = Object.keys(mapping).filter(function(key) {
        if (mapping[key])
            return data[key]
    })
    return counts.length
}

class EthnicDiversity extends Component {

    render() {

        let ethnicMapping = {diversecandidates:null,americanindian:null,africanamerican:"African-American",asian:"Asian",hispanic:"Hispanic",other:"Other",white:"Caucasian"};

        if(!this.props.data || getTotal(this.props.data,ethnicMapping ) === 0){
            return <Widget {...this.props} data={null} className={"test"}/>
        }

        return (
            <Widget {...this.props}>
                <div className="ethnic-diversity">
                    <DiversityBar  height={15} leftText="Very unfavorable" rightText="Very favorable" value={this.props.data.diversecandidates} toolTipText="Diverse candidates" />
                    <PieChart className={"ethnic-pie-container col-xs-12"} data={this.props.data} mapping={ethnicMapping} {...this.props} />
                </div>
            </Widget>
        );
    }
}

// GenderDiversity.contextTypes = {
//     intl: PropTypes.object.isRequired
// }

export default EthnicDiversity;