console.log(new Date())
console.log(window.customrole)
if (!window.customrole) {

    console.log("Window customrole is empty")
    window.customrole = {};
    window.customrole.Config = {
        "loginurl": "http://customroles.tndev.aws.talentneuron.com/login",
        "s3logouturl": "/logout",
        "serviceurl": "http://customroles-service.tndev.aws.talentneuron.com",
        "tnadminserviceurl": "http://admin-publicservice.tndev.aws.talentneuron.com",
        "tnloserviceurl": "http://tn-loservice.tndev.aws.gartner.com",
        "tnr1xurl": "https://sso.wantedanalytics.com/wa/sessions/s3/login",
        "tnr2xurl": "http://recruit.tndev.aws.talentneuron.com",
        "tnp2xurl": "http://plan.tndev.aws.talentneuron.com",
        "tnadminurl": "http://admin.tndev.aws.talentneuron.com/login",
        "tnhomepageurl": "http://home.tndev.aws.talentneuron.com",
        "dataFeedURL": "http://datafeeds.tndev.aws.talentneuron.com",
        "gtmid": "GTM-KTWZ23F",
        "isgtmenabled": false,
        "helpcenterurl": "https://tnsupport.cebglobal.com/support/solutions/19000100424",
        "googlemapsApiKey": "AIzaSyBEYNwsaVjB0Ylp13x7r_qo-d713sILZYA",
        "trackingHotJarId": 0,
        "s3Token": "VJynpFPj-7gHl-zPYl-Germ-yRhzGrnLg26M"
    };
    window.customrole.UserContext = {
        "userid": "28447",
        "email": "mohammed.hasan@talentneuron.com",
        "firstname": "hasan",
        "lastname": "mohammed",
        "title": "",
        "clientcode": "C6D3CFD4-E90E-449F-A493-922214532644",
        "clientid": 2,
        "clientname": "TN_Sales Account",
        "locale": "en",
        "s3token": "VJynpFPj-7gHl-zPYl-Germ-yRhzGrnLg26M",
        "isactive": false,
        "mimicclient": false,
        "tnproles": [],
        "geminiroles": [
            {
                "enabled": true,
                "id": "1",
                "name": "Analyze",
                "code": "Analyze",
                "isenable": true,
                "pageProductName": "TalentNeuron Recruit",
                "permissionDetail": [
                    {
                        "permissionId": 2,
                        "permissionName": "Top Certifications",
                        "permissionLevelId": 9,
                        "permissionLevelName": "Read and Export - No salary",
                        "permissionCode": "TopCertsTitlesList"
                    },
                    {
                        "permissionId": 3,
                        "permissionName": "Top Titles",
                        "permissionLevelId": 9,
                        "permissionLevelName": "Read and Export - No salary",
                        "permissionCode": "TopTitlesList"
                    },
                    {
                        "permissionId": 4,
                        "permissionName": "Top Technical Skills",
                        "permissionLevelId": 9,
                        "permissionLevelName": "Read and Export - No salary",
                        "permissionCode": "TopSkillTitlesList"
                    },
                    {
                        "permissionId": 5,
                        "permissionName": "Top Competencies (Soft Skills)",
                        "permissionLevelId": 9,
                        "permissionLevelName": "Read and Export - No salary",
                        "permissionCode": "TopSoftSkills"
                    },
                    {
                        "permissionId": 6,
                        "permissionName": "Typical Posting Period",
                        "permissionLevelId": 2,
                        "permissionLevelName": "Read",
                        "permissionCode": "TypicalPostingPeriod"
                    },
                    {
                        "permissionId": 7,
                        "permissionName": "Similar Current JDs",
                        "permissionLevelId": 8,
                        "permissionLevelName": "Read - No salary",
                        "permissionCode": "SimilarJDs"
                    },
                    {
                        "permissionId": 8,
                        "permissionName": "Education Level",
                        "permissionLevelId": 9,
                        "permissionLevelName": "Read and Export - No salary",
                        "permissionCode": "TopEducationTitlesList"
                    },
                    {
                        "permissionId": 9,
                        "permissionName": "Plain English Hiring Difficulty",
                        "permissionLevelId": 2,
                        "permissionLevelName": "Read",
                        "permissionCode": "PlainEnglishHiringDifficulty"
                    },
                    {
                        "permissionId": 10,
                        "permissionName": "Hiring Difficulty Score",
                        "permissionLevelId": 2,
                        "permissionLevelName": "Read",
                        "permissionCode": "HiringDifficultyScore"
                    },
                    {
                        "permissionId": 12,
                        "permissionName": "Demand Pressure",
                        "permissionLevelId": 2,
                        "permissionLevelName": "Read",
                        "permissionCode": "DemandPressure"
                    },
                    {
                        "permissionId": 13,
                        "permissionName": "Candidate Supply vs Demand",
                        "permissionLevelId": 2,
                        "permissionLevelName": "Read",
                        "permissionCode": "SupplyDemand"
                    },
                    {
                        "permissionId": 14,
                        "permissionName": "Average Candidate Summary",
                        "permissionLevelId": 2,
                        "permissionLevelName": "Read",
                        "permissionCode": "AverageCandidateSummary"
                    },
                    {
                        "permissionId": 15,
                        "permissionName": "Plain English Advice",
                        "permissionLevelId": 2,
                        "permissionLevelName": "Read",
                        "permissionCode": "PlainEnglishAdvice"
                    },
                    {
                        "permissionId": 16,
                        "permissionName": "Competitive Intensity",
                        "permissionLevelId": 2,
                        "permissionLevelName": "Read",
                        "permissionCode": "CompetitiveIntensity"
                    },
                    {
                        "permissionId": 17,
                        "permissionName": "Alternate Locations",
                        "permissionLevelId": 8,
                        "permissionLevelName": "Read - No salary",
                        "permissionCode": "AlternateLocations"
                    },
                    {
                        "permissionId": 19,
                        "permissionName": "Top Seniority Levels",
                        "permissionLevelId": 9,
                        "permissionLevelName": "Read and Export - No salary",
                        "permissionCode": "TopSeniorityLevels"
                    },
                    {
                        "permissionId": 20,
                        "permissionName": "Top Employers",
                        "permissionLevelId": 9,
                        "permissionLevelName": "Read and Export - No salary",
                        "permissionCode": "TopEmployers"
                    },
                    {
                        "permissionId": 21,
                        "permissionName": "Top Functions",
                        "permissionLevelId": 9,
                        "permissionLevelName": "Read and Export - No salary",
                        "permissionCode": "TopFunctions"
                    },
                    {
                        "permissionId": 33,
                        "permissionName": "Key position stats Analyze - Source",
                        "permissionLevelId": 8,
                        "permissionLevelName": "Read - No salary",
                        "permissionCode": "KeyPositionStats"
                    },
                    {
                        "permissionId": 69,
                        "permissionName": "Plain English Advice_v2",
                        "permissionLevelId": 2,
                        "permissionLevelName": "Read",
                        "permissionCode": "PlainEnglishAdvice_v2"
                    },
                    {
                        "permissionId": 70,
                        "permissionName": "Competitive Intensity_v2",
                        "permissionLevelId": 2,
                        "permissionLevelName": "Read",
                        "permissionCode": "CompetitiveIntensity_v2"
                    },
                    {
                        "permissionId": 73,
                        "permissionName": "Similar Current JDs_v2",
                        "permissionLevelId": 8,
                        "permissionLevelName": "Read - No salary",
                        "permissionCode": "SimilarJDs_v2"
                    }
                ]
            },
            {
                "enabled": true,
                "id": "2",
                "name": "Source",
                "code": "Source",
                "isenable": true,
                "pageProductName": "TalentNeuron Recruit",
                "permissionDetail": [
                    {
                        "permissionId": 23,
                        "permissionName": "Look For Candidates",
                        "permissionLevelId": 2,
                        "permissionLevelName": "Read",
                        "permissionCode": "LookForCandidates"
                    },
                    {
                        "permissionId": 24,
                        "permissionName": "Installed Talent",
                        "permissionLevelId": 9,
                        "permissionLevelName": "Read and Export - No salary",
                        "permissionCode": "InstalledTalent"
                    },
                    {
                        "permissionId": 25,
                        "permissionName": "Candidates",
                        "permissionLevelId": 2,
                        "permissionLevelName": "Read",
                        "permissionCode": "Candidates"
                    },
                    {
                        "permissionId": 26,
                        "permissionName": "Competing Company",
                        "permissionLevelId": 9,
                        "permissionLevelName": "Read and Export - No salary",
                        "permissionCode": "CompetingCompany"
                    },
                    {
                        "permissionId": 45,
                        "permissionName": "Gender Diversity",
                        "permissionLevelId": 2,
                        "permissionLevelName": "Read",
                        "permissionCode": "GenderDiversity"
                    },
                    {
                        "permissionId": 46,
                        "permissionName": "Female Candidates",
                        "permissionLevelId": 3,
                        "permissionLevelName": "Read and Export",
                        "permissionCode": "FemaleCandidate"
                    },
                    {
                        "permissionId": 47,
                        "permissionName": "Ethnicity Distribution",
                        "permissionLevelId": 2,
                        "permissionLevelName": "Read",
                        "permissionCode": "EthnicityDistribution"
                    },
                    {
                        "permissionId": 48,
                        "permissionName": "Diverse Candidates",
                        "permissionLevelId": 3,
                        "permissionLevelName": "Read and Export",
                        "permissionCode": "DiverseCandidates"
                    },
                    {
                        "permissionId": 53,
                        "permissionName": "Alternate Locations_v2",
                        "permissionLevelId": 8,
                        "permissionLevelName": "Read - No salary",
                        "permissionCode": "AlternateLocations_v2"
                    },
                    {
                        "permissionId": 71,
                        "permissionName": "Gender Diversity_v2",
                        "permissionLevelId": 2,
                        "permissionLevelName": "Read",
                        "permissionCode": "GenderDiversity_v2"
                    },
                    {
                        "permissionId": 72,
                        "permissionName": "Ethnicity Distribution_v2",
                        "permissionLevelId": 2,
                        "permissionLevelName": "Read",
                        "permissionCode": "EthnicityDistribution_v2"
                    }
                ]
            },
            {
                "enabled": true,
                "id": "3",
                "name": "Admin",
                "code": "Admin",
                "isenable": true,
                "pageProductName": "Admin",
                "permissionDetail": [
                    {
                        "permissionId": 301,
                        "permissionName": "Admin Module",
                        "permissionLevelId": 17,
                        "permissionLevelName": "Read and Edit",
                        "permissionCode": "adminmodule"
                    }
                ]
            },
            {
                "enabled": true,
                "id": "84",
                "name": "Talent",
                "code": "rolesmodule",
                "isenable": true,
                "pageProductName": "TalentNeuron Plan",
                "permissionDetail": [
                    {
                        "permissionId": 366,
                        "permissionName": "Talent Trends",
                        "permissionLevelId": 3,
                        "permissionLevelName": "Read and Export",
                        "permissionCode": "roles-occupations"
                    },
                    {
                        "permissionId": 367,
                        "permissionName": "Hiring Indicators",
                        "permissionLevelId": 3,
                        "permissionLevelName": "Read and Export",
                        "permissionCode": "roles-hiringindicators"
                    },
                    {
                        "permissionId": 368,
                        "permissionName": "Talent Diversity",
                        "permissionLevelId": 3,
                        "permissionLevelName": "Read and Export",
                        "permissionCode": "roles-diversity"
                    },
                    {
                        "permissionId": 369,
                        "permissionName": "Talent Remote work",
                        "permissionLevelId": 3,
                        "permissionLevelName": "Read and Export",
                        "permissionCode": "roles-remotework"
                    }
                ]
            },
            {
                "enabled": true,
                "id": "86",
                "name": "Features",
                "code": "planfeatures",
                "isenable": true,
                "pageProductName": "TalentNeuron Plan",
                "permissionDetail": [
                    {
                        "permissionId": 377,
                        "permissionName": "Report Builder",
                        "permissionLevelId": 3,
                        "permissionLevelName": "Read and Export",
                        "permissionCode": "report-builder"
                    },
                    {
                        "permissionId": 378,
                        "permissionName": "Components & Projects Publish",
                        "permissionLevelId": 17,
                        "permissionLevelName": "Read and Edit",
                        "permissionCode": "projectscomponents-managepublish"
                    },
                    {
                        "permissionId": 379,
                        "permissionName": "Industries filter",
                        "permissionLevelId": 3,
                        "permissionLevelName": "Read and Export",
                        "permissionCode": "industryfilter"
                    }
                ]
            },
            {
                "enabled": true,
                "id": "24",
                "name": "Custom Roles",
                "code": "CustomRoles",
                "isenable": true,
                "pageProductName": "Custom Roles",
                "permissionDetail": [
                    {
                        "permissionId": 418,
                        "permissionName": "CRUploadDelete",
                        "permissionLevelId": 3,
                        "permissionLevelName": "Read and Export",
                        "permissionCode": "cr-uploaddelete"
                    }
                ]
            },
            {
                "enabled": true,
                "id": "88",
                "name": "Datafeeds",
                "code": "Datafeeds",
                "isenable": true,
                "pageProductName": "Datafeeds",
                "permissionDetail": null
            },
            {
                "enabled": true,
                "id": "90",
                "name": "TalentNeuron API",
                "code": "TalentNeuron API",
                "isenable": true,
                "pageProductName": "TalentNeuron API",
                "permissionDetail": null
            },
            {
                "enabled": true,
                "id": "44",
                "name": "Competition",
                "code": "competitionmodule",
                "isenable": true,
                "pageProductName": "TalentNeuron Plan",
                "permissionDetail": [
                    {
                        "permissionId": 267,
                        "permissionName": "Competitors",
                        "permissionLevelId": 3,
                        "permissionLevelName": "Read and Export",
                        "permissionCode": "competition-competitors"
                    },
                    {
                        "permissionId": 268,
                        "permissionName": "Location",
                        "permissionLevelId": 3,
                        "permissionLevelName": "Read and Export",
                        "permissionCode": "competition-locations"
                    },
                    {
                        "permissionId": 269,
                        "permissionName": "Job design",
                        "permissionLevelId": 3,
                        "permissionLevelName": "Read and Export",
                        "permissionCode": "competition-jobdesign"
                    },
                    {
                        "permissionId": 419,
                        "permissionName": "Employer branding",
                        "permissionLevelId": 3,
                        "permissionLevelName": "Read and Export",
                        "permissionCode": "competition-employerbranding"
                    }
                ]
            },
            {
                "enabled": true,
                "id": "45",
                "name": "Skills",
                "code": "skillsmodule",
                "isenable": true,
                "pageProductName": "TalentNeuron Plan",
                "permissionDetail": [
                    {
                        "permissionId": 270,
                        "permissionName": "Top Skills",
                        "permissionLevelId": 3,
                        "permissionLevelName": "Read and Export",
                        "permissionCode": "skills-topskills"
                    },
                    {
                        "permissionId": 271,
                        "permissionName": "Skill Adjacencies",
                        "permissionLevelId": 3,
                        "permissionLevelName": "Read and Export",
                        "permissionCode": "skills-adjacencies"
                    },
                    {
                        "permissionId": 272,
                        "permissionName": "Skills Evolution",
                        "permissionLevelId": 3,
                        "permissionLevelName": "Read and Export",
                        "permissionCode": "skills-evolution"
                    },
                    {
                        "permissionId": 273,
                        "permissionName": "Skills Placeholder2",
                        "permissionLevelId": 3,
                        "permissionLevelName": "Read and Export",
                        "permissionCode": "skills-placeholder2skills"
                    }
                ]
            },
            {
                "enabled": true,
                "id": "46",
                "name": "Locations",
                "code": "locationsmodule",
                "isenable": true,
                "pageProductName": "TalentNeuron Plan",
                "permissionDetail": [
                    {
                        "permissionId": 274,
                        "permissionName": "Global Location Scan",
                        "permissionLevelId": 3,
                        "permissionLevelName": "Read and Export",
                        "permissionCode": "locations-globallocationscan"
                    },
                    {
                        "permissionId": 275,
                        "permissionName": "Location - Summary",
                        "permissionLevelId": 3,
                        "permissionLevelName": "Read and Export",
                        "permissionCode": "locations-summary"
                    },
                    {
                        "permissionId": 276,
                        "permissionName": "Location - Talent Pool",
                        "permissionLevelId": 3,
                        "permissionLevelName": "Read and Export",
                        "permissionCode": "locations-talentpool"
                    },
                    {
                        "permissionId": 402,
                        "permissionName": "Location - Graduate Talent",
                        "permissionLevelId": 3,
                        "permissionLevelName": "Read and Export",
                        "permissionCode": "locations-graduatetalent"
                    },
                    {
                        "permissionId": 403,
                        "permissionName": "Location - Demand",
                        "permissionLevelId": 3,
                        "permissionLevelName": "Read and Export",
                        "permissionCode": "locations-demand"
                    },
                    {
                        "permissionId": 404,
                        "permissionName": "Location - Competitors",
                        "permissionLevelId": 3,
                        "permissionLevelName": "Read and Export",
                        "permissionCode": "locations-competitors"
                    },
                    {
                        "permissionId": 405,
                        "permissionName": "Location - Cost",
                        "permissionLevelId": 3,
                        "permissionLevelName": "Read and Export",
                        "permissionCode": "locations-cost"
                    },
                    {
                        "permissionId": 406,
                        "permissionName": "Location - Factors",
                        "permissionLevelId": 3,
                        "permissionLevelName": "Read and Export",
                        "permissionCode": "locations-locationfactors"
                    },
                    {
                        "permissionId": 408,
                        "permissionName": "Location Diversity",
                        "permissionLevelId": 3,
                        "permissionLevelName": "Read and Export",
                        "permissionCode": "locations-diversity"
                    }
                ]
            }
        ],
        "personaID": 15,
        "responsibilityIdsCSV": "211",
        "geminiUserId": 28447,
        "localestr": null,
        "adminenabled": true,
        "tnpenabled": true,
        "tnr1xenabled": false,
        "tnr2xenabled": true,
        "dataFeedEnabled": true,
        "tnp2xenabled": true,
        "customroleenabled": true,
        "ishotjarenabled": true
    };
}

class Config {

    //URL
    static get recuritURL() {
        return (window.customrole.Config.tnr2xurl ? window.customrole.Config.tnr2xurl : "http://recruit.tndev.aws.talentneuron.com/");
    };
    static get homeURL() {
        return (window.customrole.Config.tnhomepageurl ? window.customrole.Config.tnhomepageurl : "http://home.tndev.aws.talentneuron.com");
    };
    static get plan2xURL() {
        return (window.customrole.Config.tnp2xurl ? window.customrole.Config.tnp2xurl : "http://plan.tndev.aws.talentneuron.com");
    };
    static get customRoleURL() {
        return (window.customrole.Config.customRoleURL ? window.customrole.Config.customRoleURL : "http://customroles.tndev.aws.talentneuron.com");
    };
    static get serviceURL() {
        return (window.customrole.Config.serviceurl ? window.customrole.Config.serviceurl : "http://customroles-service.tndev.aws.talentneuron.com");
    };
    static get helpCenterURL() {
        return (window.customrole.Config.helpcenterurl ? window.customrole.Config.helpcenterurl : "https://talentneuron.my.site.com/Support/s");
    }
    static get s3URL() {
        return (window.customrole.Config.s3url ? window.customrole.Config.s3url : 'http://login.tndev.aws.talentneuron.com/sso');
    };
    static get loginURL() {
        return (window.customrole.Config.loginurl ? window.customrole.Config.loginurl : 'http://customroles.tndev.aws.talentneuron.com');
    };
    static get getProductHomeURL() {
        return (window.customrole.Config.tnhomepageurl ? window.customrole.Config.tnhomepageurl : 'http://home.tndev.aws.talentneuron.com');
    };
    static get tnpURL() {
        return (window.customrole.Config.tnpurl ? window.customrole.Config.tnpurl : 'https://tnplan-dev.gartner.com');
    };
    static get tnr1xURL() {
        return (window.customrole.Config.tnr1xurl ? window.customrole.Config.tnr1xurl : 'https://sso.wantedanalytics.com/wa/sessions/s3/login');
    };
    static get tnr2xURL() {
        return (window.customrole.Config.tnr2xurl ? window.customrole.Config.tnr2xurl : 'https://dev-gmweb.dev.ceb');
    };
    static get tnAdmin() {
        return (window.customrole.Config.tnadminurl ? window.customrole.Config.tnadminurl : 'http://admin.tndev.aws.talentneuron.com/login');
    };
    static get dataFeedURL() {
        return (window.customrole.Config.dataFeedURL ? window.customrole.Config.dataFeedURL : 'http://datafeeds.tndev.aws.talentneuron.com');
    };
    static get tnp2xURL() {
        return (window.customrole.Config.tnp2xurl ? window.customrole.Config.tnp2xurl : 'http://plan.tndev.aws.talentneuron.com/');
    };
    static get logoutURL() {
        return (window.customrole.Config.s3logouturl ? window.customrole.Config.s3logouturl : Config.s3URL + '/logout');
    };
    static get gaTrackingCode() {
        return (window.customrole.Config.gaTrackingCode ? window.customrole.Config.gaTrackingCode : 'UA-87798143-2');
    };
    static get tnHomePageUrl() {
        return (window.customrole.Config.tnhomepageurl ? window.customrole.Config.tnhomepageurl : 'http://home.tndev.aws.talentneuron.com');
    };
    static get getUserContext() {
        return (window.customrole.UserContext ? window.customrole.UserContext : null);
    };

    static get getAdminServiceURL() {
        return (window.customrole.Config.tnadminserviceurl ? window.customrole.Config.tnadminserviceurl : 'http://tnadmin-publicservice-external.tndev.aws.gartner.com');
    }
    static get getLOServiceURL() {
        return (window.customrole.Config.tnloserviceurl ? window.customrole.Config.tnloserviceurl : 'http://tn-loservice.tndev.aws.gartner.com');
    }
    static get currentOrigin() {
        return (window.location.hostname === 'localhost' ? 'http://customroles.tndev.aws.talentneuron.com' : window.location.origin);
    }
    static get googleMapsApiKey() {
        return window.customrole.Config.googlemapsApiKey
            ? window.customrole.Config.googlemapsApiKey
            : "AIzaSyBzmGhfv7o8XRtqlUjbe2UrjjI3-MmljeU";
    }
    static get tnAxonApiURL() {
        return window.customrole.Config.axonDocUrl ? window.customrole.Config.axonDocUrl : 'http://home.tndev.aws.talentneuron.com/api'
    }
    static get kcAuthUrl () {
        return window.customrole.Config.kcAuthUrl ? window.customrole.Config.kcAuthUrl : 'http://axon-keykloak-service.tndev.aws.gartner.com'
    }
    static get staffingURL () {
        return window.customrole.Config.staffingURL ? window.customrole.Config.staffingURL : 'http://staffing.tndev.aws.talentneuron.com'
    }
    static get kcAuthRealm () {
        return window.customrole.Config.kcAuthRealm ? window.customrole.Config.kcAuthRealm : 'tn-ui'
    }
    static get kcAuthClient () {
        return window.customrole.Config.kcAuthClient ? window.customrole.Config.kcAuthClient : 'tnui-web-app'
    }
}

//Path
export default Config
