import React, { Component } from "react";
import ExpandingPanelContent from './../ExpandingPanelContent';
import PropTypes from 'prop-types';
import './style.css';

class ExpandingPanel extends Component{

    constructor(props){
        super(props);
        this.state = {
            expanded : (this.props.isReport)?true:false
        }
        this.slidePanel= this.slidePanel.bind(this);
        this.slideRef = React.createRef();
    }

    slidePanel(scrollPoint) {
        this.props.scrollHandler(this.state.expanded, this.scrollPoint);
        (this.state.expanded)?this.slideRef.current.classList.remove('open'):this.slideRef.current.classList.add('open');
        this.setState((prevState)=>{
            return {
                expanded: !prevState.expanded
            };
        });
    }

    render(){
        let active = (this.state.expanded)?'active':'';
        let icon = (this.state.expanded) ? 'ga-top' : 'ga-bottom'
        let classRepName = (this.props.isReport) ? 'open' : '';
        return(
        <div className="col-xs-12 expandingContainer">
            <div ref = {this.slideRef} className={`slidePanel ${classRepName}`}><ExpandingPanelContent {...this.state} {...this.props} scrollPoint={this.scrollPoint} /></div>
            <div className="expandBtnBlock">
                <div className={`expandBtn ${active}`} onClick={this.slidePanel}>
                    <div>
                        <span className={`expandBtnText ${active}`}>{(this.state.expanded)?this.props.showLessText:this.props.showMoreText}</span>
                        <span className={`ga ${icon}`}></span>
                    </div>
                </div>
            </div>
            <span ref={node => (this.scrollPoint = node)}></span>
        </div>
        )
    }
}

ExpandingPanel.contextTypes = {
    intl: PropTypes.object.isRequired
}

export default ExpandingPanel;