import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import "./polyfill.js";
import 'core-js/es/symbol';
import 'core-js/es/symbol/iterator';
import React from 'react';
import { TNThemeProvider, LoadingCharts } from 'tn-components-library'
import ReactDOM from 'react-dom'; 
import { Provider } from 'react-redux';
import './css/style.css';
import authAPI from './http/auth';
import Root from './container/layout/Root';
import auth from './auth/index';
import ErrorBoundary from './component/ErrorBoundary';
import { IntlProvider, addLocaleData } from 'react-intl';
//import the store with his configuration
import configureStore from './store/config';
import en from 'react-intl/locale-data/en';
import messages_en from "./localization/en.json";
import KeyCloakAuthInstance from './keyAuth.js';


const searchParams = new URLSearchParams(location.search);
const authtoken = searchParams.get('token');

if (authtoken) {
  KeyCloakAuthInstance.setAuthTokenForReport(authtoken)
  postAuthenticationCallback()
} else {
  KeyCloakAuthInstance.tryAuthentication().then(isAuthSuccess => {
    if (isAuthSuccess) {
      postAuthenticationCallback()
    }
  }).catch(error => {
    console.log(error)
  })
}

function postAuthenticationCallback () {
  Promise.all([
    authAPI.getAuthData()
  ])
    .then(responses => {
      if (responses.constructor === [].constructor) {
        try {
          store.subscribe(renderRoot);
          auth.parseAuthResponse(responses[0], store);
        } catch (error) {
          console.log(error);
          ReactDOM.render(<ErrorBoundary error={error.message} errorInfo={error.stack} />, document.getElementById('root'));
        }
  
      } else {
        auth.setAuthFailed(store);
      }
    })
    .catch(error => {
      console.log(error);
      ReactDOM.render(<ErrorBoundary error={error.message} errorInfo={error.stack} />, document.getElementById('root'));
    });
}

const messagesList = {
  'en': messages_en,
  // 'fr':messages_fr
};
addLocaleData([...en]);

// Define user's language. Different browsers have the user locale defined
// on different fields on the `navigator` object, so we make sure to account
// for these different by checking all of them
const language = (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage;

// Split locales with a region code
const languageWithoutRegionCode = 'en';// TODO should refactor when other languages are supported

// Try full locale, fallback to locale without region code, fallback to en
const messages = messagesList[language] || messagesList[languageWithoutRegionCode] || messagesList.en;
ReactDOM.render(<div className='root-loading'><LoadingCharts title='Loading' subTitle='TalentNeuron' /></div>, document.getElementById('root'));
const store = configureStore();
//const store = createStore(authReduce);
const renderRoot = () => {

  ReactDOM.render(
    <IntlProvider locale={languageWithoutRegionCode} messages={messages}>
      <Provider store={store}>
      <TNThemeProvider>
        <ErrorBoundary>
          <Root />
        </ErrorBoundary>
        </TNThemeProvider>
      </Provider>
    </IntlProvider>, document.getElementById('root'));
};

