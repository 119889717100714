import Config from '../profiles';
import KeyCloakAuthInstance from '../keyAuth';
const gtmDataLayer = function () {
	return window.dataLayer || [];
};

const defaultData = {
	page: 'Search Custom Roles',
	category: 'Custom Roles Search',
	action: '',
	label: '',
	dimension7: null,
	dimension8: null,
	dimension9: null,
};

const init = (userContext) => {
	const sessionId = KeyCloakAuthInstance.getParsedToken().sid;
	if (sessionId === undefined || userContext.userid === null) return;
	gtmDataLayer().push({
		product: 3,
		user: {
			userId: userContext.geminiUserId,
			sessionId,
		},
		trackingUrl: `${Config.getAdminServiceURL}/v1/ut/userInteraction`,
	});
};

const events = {
	//MUST prefix "tncr." to all event names
	viewResult: {
		event: 'tncr.viewResult',
		category: 'Custom Roles Search',
		action: 'Search Validation',
	},
	linkClick: {
		event: 'tncr.linkClick',
		action: 'Link Clicked',
	},
	viewed: {
		event: 'tncr.viewed',
		action: 'Viewed',
	},
	search: {
		event: 'tncr.search',
		action: 'No Results Found',
	},
};

const track = (gtmEvent, eventData) => {
	let trackingInfo = { ...defaultData, ...gtmEvent, ...eventData };
	if (trackingInfo.page && trackingInfo.category && trackingInfo.action) {
		gtmDataLayer().push(trackingInfo);
	}
};

export default { init, events, track };

