import React, { Component } from "react";

export default class ExpandingPanelContent extends Component{
    constructor(props){
        super(props);
        this.renderExpandedContainer = this.renderExpandedContainer.bind(this);
    }

    renderExpandedContainer(){
        //Can modify in case a common header/footer is needed for Expanded Panel
        return <div className="expandedContentSection">
            {this.props.renderExpandedView()}
        </div>
    }

    render() {
            return <div>
                    {this.renderExpandedContainer()}
                </div>
    }
} 