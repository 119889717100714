// React Imports
import React, {Component} from "react";
import Highcharts from "highcharts";
import HighChartsContainer from '../../component/HighCharts';
import highchartsmore from "highcharts/highcharts-more";
import solidgauge from "highcharts/modules/solid-gauge";
import { formatNumber } from "../../utils";
import './style.css';

highchartsmore(Highcharts);


class SolidGuage extends React.PureComponent{

    constructor(props){
        super(props);
        this.buildOptions = this.buildOptions.bind(this);
    }

    buildOptions(){
        let percent = this.props.skillPercent,value = this.props.skillValue,
        guageCenter =  this.props.isReport?['58px','58px']:['80px', '88px'],
        paneSize = this.props.isReport?'125%':'119%',
        percentPosition = this.props.isReport?-20:-30,
        gaugeOptions = {
            chart: {
                type: 'solidgauge',
                style: {
                    fontFamily: "'Graphik Web',Arial",
                    fontSize: "16px"
                }
            },
            title: {
                text: ''
            },
            pane: {
                center: guageCenter,
                size : paneSize,
                startAngle: -130,
                endAngle: 130,
                background: {
                    backgroundColor: '#e0e0e0',
                    innerRadius: '70%',
                    outerRadius: '90%',
                    shape: 'arc',
                    borderColor: 'transparent'
                }
            },
            tooltip: {
                enabled: false
            },
            yAxis: {
                stops: [
                    [0.1, '#ff540a'], 
                ],
                lineWidth: 0,
                minorTickInterval: null,
                tickAmount: 0,
                min: 0,
                max: 100,
                visible : false
            },
            plotOptions: {
                solidgauge: {
                    dataLabels: {
                        y: percentPosition,
                        borderWidth: 0,
                        useHTML: true
                    }
                }
            },
            series: [{
                type: "solidgauge",
                name: 'Skills',
                data: [{
                    y : percent,
                    value : formatNumber(value),
                    radius: 96,
                    innerRadius: 66
                }],
                dataLabels: {
                    format: '<div class="guageDataContainer"><span class="guagePercent">{point.y}%</span>' +
                    '<span class="guageNumber">{point.value}</span></div>'
                }
            }],
            credits: {
                enabled: false
            }
        }
        return gaugeOptions;
    }

    render (){
        let style = (this.props.isReport)?{height: '120px',width:'135px'}:{height: '165px',width:'180px'};
        return (
                <HighChartsContainer
                        options={this.buildOptions()}
                        container={this.props.container}
                        modules = {[solidgauge]}
                        style ={style}
                    />
        );
    }

};

export default SolidGuage;
