import React, { Component } from "react";
import Widget from "../Widget";
import { formatNumber } from "../../utils";
import PropTypes from 'prop-types';
import KeystatTile from '../../component/KeystatTile/';

class KeyStats extends Component {
    
    render() {
        if(!this.props.data){
            return <Widget {...this.props} />
        }
        let intmsg = this.context.intl;
        let hdRange = this.props.data.hiringdifficulty;

        let days = intmsg.formatMessage({ id: "days" }),
        candidate_supply = intmsg.formatMessage({ id: "candidate_supply" }),
        est_proportion_looking = intmsg.formatMessage({ id: "est_proportion_looking" }),
        avg_posting_period = intmsg.formatMessage({ id: "avg_posting_period" }),
        jobs_posted = intmsg.formatMessage({ id: "jobs_posted" }),
        hiring_difficulty = intmsg.formatMessage({ id: "hiring_difficulty" });

        let hiringdifficultyText = () => {
            let localizationCode = "not_difficult";
    
            if (hdRange >= 20 && hdRange <= 40) {
                localizationCode = "slightly_difficult";
            }
            else if (hdRange >= 41 && hdRange <= 60) {
                localizationCode = "moderately_difficult";
            }
            else if (hdRange >= 61 && hdRange <= 80) {
                localizationCode = "difficult";
            }
            else if (hdRange >= 81 && hdRange <= 100) {
                localizationCode = "very_difficult";
            }
    
            return  localizationCode
        }
        let hdText = intmsg.formatMessage({ id: hiringdifficultyText() });
        let naText = intmsg.formatMessage({ id: "not_applicable" });
        return (
            <Widget  {...this.props}>
                <div className="row">
                    <KeystatTile name={candidate_supply} classNames="candidates-supply" value={(this.props.data.candidatesupply)?formatNumber(this.props.data.candidatesupply):naText} colWidth={3} ></KeystatTile>
                    {/* <KeystatTile name={est_proportion_looking} classNames="candidates-looking" value={(this.props.data.estproportionlooking)?this.props.data.estproportionlooking + "%" : naText} colWidth={2} /> */}
                    <KeystatTile name={avg_posting_period} classNames="posting-period" value={(this.props.data.avgpostingperiod)?this.props.data.avgpostingperiod + " " + days:naText} colWidth={3} ></KeystatTile>
                    <KeystatTile name={jobs_posted} classNames="jobs-posted" value={(this.props.data.jobsposted)?formatNumber(this.props.data.jobsposted):naText} colWidth={3} ></KeystatTile>
                    <KeystatTile name={hiring_difficulty} classNames={hiringdifficultyText()} value={(hdRange)?hdRange + "% " + hdText:naText} colWidth={3} ></KeystatTile>
                </div>
            </Widget>
        );
    }
}

KeyStats.contextTypes = {
    intl: PropTypes.object.isRequired
}
export default KeyStats;
