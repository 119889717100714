import React, { Component } from "react";
import Highcharts from "highcharts";

class HighChartsContainer extends Component {
    componentDidMount() {
        // Extend Highcharts with modules
        if (this.props.modules) {
            this.props.modules.forEach(function(module) {
                module(Highcharts);
            });
        }
        // Set container which the chart should render to.

        this.chart = new Highcharts["Chart"](
            this.props.container,
            this.props.options
        );
    }
    //Destroy chart before unmount.
    componentWillUnmount() {
        this.chart.destroy();
    }
    componentDidUpdate() { 
        let event = document.createEvent("Event");
        event.initEvent("resize", false, true); 
        window.dispatchEvent(event);
    }
    render() {
        return React.createElement("div", { id: this.props.container,style: this.props.style});
    }
}

export default HighChartsContainer;
