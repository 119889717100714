
import React from "react";
import numeral from "numeral";
import Config from './profiles';
export const addEllipsis = (name, n) => {
	name = (name.length > n) ? (name.substring(0, n) + '...') : name;
	return name;
}

// return true if given value is undefined or null or empty or contains only whitespaces
export const isAbsoluteEmpty = (value) => {
	if (value === undefined || value === null || String(value).trim().length === 0) {
		return true;
	}
	return false;
}

export const convertToFile = (data, filename, mime) => {
    var blob = new Blob([data], {type: mime || 'application/octet-stream'});
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
        // IE workaround for "HTML7007: One or more blob URLs were 
        // revoked by closing the blob for which they were created. 
        // These URLs will no longer resolve as the data backing 
        // the URL has been freed."
        window.navigator.msSaveBlob(blob, filename);
    }
    else {
        var blobURL = window.URL.createObjectURL(blob);
        var tempLink = document.createElement('a');
        tempLink.style.display = 'none';
        tempLink.href = blobURL;
        tempLink.setAttribute('download', filename); 
        
        // Safari thinks _blank anchor are pop ups. We only want to set _blank
        // target if the browser does not support the HTML5 download attribute.
        // This allows you to download files in desktop safari if pop up blocking 
        // is enabled.
        if (typeof tempLink.download === 'undefined') {
            tempLink.setAttribute('target', '_blank');
        }
        
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(blobURL);
    }
}

export const getParameterByName = (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export const isNumber = n => {
    return !isNaN(parseFloat(n)) && isFinite(n);
};

export const formatNumber = n => {
    if(isNumber(n)){
        return numeral(n).format("0,0");
    }
    return 0;
};
export const HxCodeToDangerousDOMElelemt = (str, className) =>{

    const danger_dom = <span className={className} dangerouslySetInnerHTML={{__html: str}}/>
    return danger_dom;
};

export const  array_move = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
};

export const getProductsForFooter = (config, userDetails) => {
    let  products= [
        {
            text: "Plan",
            link: config.PLAN2x,
            hasAccess: userDetails.PLAN2x
        },
        {
            text: "Recruit",
            link: config.Tnr2xURL,
            hasAccess: userDetails.Tnr2xEnabled
        },
        {
            text: "Custom Roles",
            link: config.CustomRoleURL,
            hasAccess: userDetails.CustomRoleEnabled
        }
    ]

    return products.filter(el => el.hasAccess).map(el=> { return {text:el.text, link:el.link}})
}
export const  AppUrls = {
    TnpURL : Config.tnpURL,
    AdminURL : Config.tnAdmin,
    Tnr1xURL : Config.tnr1xURL,
    Tnr2xURL : Config.tnr2xURL,
    HelpCentreURL : Config.helpCenterURL,
    HomeURL : Config.tnHomePageUrl,
    CustomRoleURL : "/",
    TrainingURL : "",
    LogoutURL : Config.loginURL.replace("login", "logout"),
    gtmEvent: 'tncr.nav',
    PLAN2x : Config.tnp2xURL
}

export const footerUrls = {
	ourData: `${Config.homeURL}/learnmore/ourdata`,
	countries: `${Config.homeURL}/learnmore/countries`,
	productUpdates: `${Config.homeURL}/learnmore/productupdates`,
	caseStudies: `${Config.homeURL}/learnmore/casestudies`,
	insights: `${Config.homeURL}/learnmore/insights`,
	plan: `${Config.plan2xURL}/#`,
	recruit: Config.recuritURL,
	customRoles: '/',
	helpCenter: Config.helpCenterURL,
    staffing: Config.staffingURL,
	contactUs: 'mailto:support@talentneuron.com',
	termsOfService: 'https://www.talentneuron.com/terms-and-conditions/',
	privacyStatement: 'https://www.talentneuron.com/privacy-policy/'
}

export const getUploadRolePermission = () => {
    const geminiRoles = window.customrole.UserContext.geminiroles || []
    const permisbleModules = geminiRoles.filter(x => x.code === 'CustomRoles' && x.isenable === true);
    const rolePermissions = permisbleModules.length ? permisbleModules[0].permissionDetail?.filter(y => y.permissionCode === 'cr-uploaddelete' && y.permissionLevelName === "Read and Export") : [];
    return rolePermissions && rolePermissions.length > 0
}

export const getDeleteRolePermission = () => {
    const geminiRoles = window.customrole.UserContext.geminiroles || []
    const mimicclient = window.customrole.UserContext.mimicclient || false
    const permisbleModules = geminiRoles.filter(x => x.code === 'CustomRoles' && x.isenable === true)
    const rolePermissions = permisbleModules.length ? permisbleModules[0].permissionDetail?.filter(y => y.permissionCode === 'cr-uploaddelete' && y.permissionLevelName === "Read and Export") : [];
    return mimicclient && rolePermissions && rolePermissions.length > 0
}

export const getRoleDetails = (roleDetails = []) => {
    const roles = roleDetails.map((role) => {
      return (
        {
          id: role.id,
          pageName: role.name,
          pageCode: role.code,
          isEnabled: role.isenable,
          pageProductName: role.pageProductName,
          permissionDetails: role.permissionDetail || []
        }
      )
    })
    return roles
}

export const isStaffingEnabled = (roles) => {
    const staffingRole = roles.find(x => x.pageCode === 'TalentNeuron Staffing')
    return staffingRole ? staffingRole.isEnabled : false
}

export const TOKEN_REFRESH_TIME = 1750000;
export const TOKEN_REFRESH_THRESHOLD_TIME = 70;

export const UniversalSearchConfig = () => {
    return {
        roles: getRoleDetails(window.customrole?.UserContext?.geminiroles),
        planAccess: window.customrole.UserContext.tnp2xenabled,
        recruitAccess: window.customrole.UserContext.tnr2xenabled,
        customRoleAccess: true,
        dataFeedAccess: window.customrole.UserContext.dataFeedEnabled,
        activeProduct: 'customrole',
        urls: {
          customrole: `${Config.customRoleURL}/`,
          insight: `${Config.homeURL}/learnmore/insights`,
          plan: `${Config.plan2xURL}/#`,
          recruit: `${Config.recuritURL}/#`,
          datafeeds: Config.dataFeedURL,
          home: Config.homeURL,
          customSolution: `${Config.homeURL}/customsolutions`
        },
    }
}
