import {createAction} from 'redux-actions'
import Config from '../profiles';
import http from '../http/http';
export const setCurrencylist = createAction("SET_CURRENCY_LIST");
export const setSelected = createAction("SET_SELECTED");

export function  getCurrencylist  (){
    return dispatch =>{

        return new Promise((resolve, reject) => {
            http.get(Config.serviceURL +"/v1/cr/jobrole/currencylist")
                .then((response)=>{
                    if(response.data.isError){
                        console.log("error when calling the service jobrole");
                        reject(null);
                    }
                    resolve (response.data.data);
                    dispatch(setCurrencylist(response.data.data));
                })
                .catch((error)=>{
                    console.log("service call failed => jobrole");
                    console.log(error)
                    reject(null)
                })
        })
    }

}