import React from 'react';
import PropTypes from 'prop-types';
import "./styles.css"

const KeystatTile = (props) => {
    return (<div className={`col-md-${props.colWidth} ${props.classNames}`}>
            <div className="keystat-container">
                <div className={'keystat-name'}>{props.name}</div>
                <div className={`keystat-value`}> {props.value}</div>
            </div>
        </div>);
}

KeystatTile.propTypes = {
    colWidth:PropTypes.number,
    classNames: PropTypes.string,
    name:PropTypes.string,
    value:PropTypes.string
};

KeystatTile.defaultProps = {
    colWidth:2,
    classNames:'',
    name:'',
    value:''
};

export default KeystatTile;