import axios from 'axios';
import Config from '../profiles';
import KeyCloakAuthInstance from '../keyAuth';

const http = axios.create({
    withCredentials: true
})

http.interceptors.request.use(function (config) {
     config.headers.Authorization = `Bearer ${KeyCloakAuthInstance.getAccessToken()}`;
    return config
})

http.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response && error.response.status === 403) {
        return KeyCloakAuthInstance.logout();
    }
    if (typeof(error.response) === 'undefined' || error.response.status === 401) {
            window.location = Config.customRoleURL;        
    } else {
        return Promise.reject(error);
    }
});

export default http;
