import React from 'react'
import {FilterGrid} from 'talentneuron---ui-components-library'
import {Typography} from 'tn-components-library'
import {addEllipsis, formatNumber, array_move} from '../../utils'
import Widget from "../../component/Widget";
import './styles.css'

const getMaxPercent = (array) => {

    return Math.max(...array);
}
const tableTalentHeaderDefinition = () => {
    let columns = [];

    columns.push({
            colKey: 'name',
            displayName: "Industry",
            type: 'alpha',
            className: 'col-md-5 bold col-name text-left',
            renderer: nameRender
        },
        {
            colKey: 'count',
            displayName: null,
            disableSort: true,
            type: 'integer',
            className: 'col-md-5 bars no-gutters col-bar text-left',
            renderer: barRender
        },
        {
            colKey: 'count',
            displayName: "Total count",
            type: 'integer',
            className: 'col-md-2 col-count  text-right',
            renderer: countRender
        });
    return columns;
};

const nameRender = (props) => {
    let industryName = props.name;
    return (industryName.length > 40) ? (addEllipsis(industryName, 37)) : industryName;
}
const barRender = (props) => {
    let coloredPercentage = Math.ceil((100 * props.percent) / props.maxPercentage);

    return (
        <div className={"top-title-nested-middle-grid-container"}>
            <div className="gem-bar-item-bardata-color pull-left"
                 style={{width: coloredPercentage + "%", backgroundColor: "#009ad7"}}>
                <div className="tooltiptext col-xs-12">
                        <div className={"title col-xs-10 no-padding"}> {props.name}</div>
                        <div className={"value col-xs-2 no-padding"}> {props.percent + "%"}</div>

                </div>
            </div>
        </div>
    );
};
const countRender = (props) => {
    return formatNumber(props.count);
};

const noDataRenderer= ( ) =>{
    const message ="Not enough data found to calculate results."
    return (
        <div className="gem-error-message padding-bottom-30 ">
            <span className="analysisIcon ga ga-search-analysis-01" style={{color: "#616161"}}></span>
            <Typography category='heading2' value={ message}></Typography>
        </div>
    )
}
class  TopIndustries extends React.Component{

    constructor(props){
        super(props)
        this.state={
            sort: { column: "count", direction: "dsc" },
        }
    }
    onSort(colKey, direction) {
        this.setState(pre => {
            return {
                sort: { column: colKey, direction: direction },
            }
        })
    }


        render()
        {
            let percents = this.props.data && this.props.data.map(el => {
                return el.percent;
            });
            let maxPercentage = percents && getMaxPercent(percents);
            let data = this.props.data && this.props.data.map(el => {
                return {
                    ...el,
                    maxPercentage: maxPercentage
                }
            });
            const postSort = (array) => {

                let index = array.findIndex(el => el.name === "Others");

                return array_move(array, index, array.length - 1)
            };
            const setSeparator = (index) => {

                if (this.props.others.length - 1 === index) {
                    return ""
                }
                if (this.props.others.length === 2 && index === 0) {
                    return " and "
                } else if (this.props.others.length > 2) {
                    return index < this.props.others.length - 2 ? ", " : " and "
                } else {
                    return ""
                }
            }

            return (
                <Widget  {...this.props}>
                    <div className={"industry-filter-table"}>
                        <FilterGrid className="data-table" positionTitleAbove=""
                                    columnDefs={tableTalentHeaderDefinition()}
                                    dataSet={data}
                                    customNoDataComponent={noDataRenderer()}
                                    postSort={postSort}
                                    nodataMessage="Please select at least one Location to see the results"
                                    initialSortColumn={this.state.sort.column}
                                    initialSortDirection={this.state.sort.direction}
                                    onSortCallBack={this.onSort.bind(this)}

                        >
                        </FilterGrid>

                        {
                            (this.props.others && this.props.others.length > 0) && <div className={"OtherIndustries"}>
                                <span className={"title"}>{"Other Industries:"}</span>
                                <div className={"othersList"}>
                                    {
                                        this.props.others.map((el, index) => <span>{el + setSeparator(index)}</span>)
                                    }
                                </div>
                            </div>

                        }
                    </div>
                </Widget>

            )
        }

}

export default  TopIndustries