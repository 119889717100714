import { Component } from 'react';
import PropTypes from 'prop-types'
import Config from '../../profiles'

export class LoginRedirect extends Component {

  componentWillMount() {
    if (window.userObject) {
      window.location = Config.loginURL
    }
  }

  render() {
    return null;
  }

}

LoginRedirect.contextTypes = {
  store: PropTypes.object
}


export default LoginRedirect;