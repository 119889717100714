
import {handleActions} from 'redux-actions'

import {
    setDashboardData,
    setCandidateProfilesData,
    setSourceLoading
} from '../actions/dashboard'


export const INITIAL_STATE = {
    data:null,
    sources:{
        isLoading:false,
        list:[]
    }
};

export default handleActions(
    {
        [setDashboardData]: (state, {payload}) => {
            return {
                ...state,
                data:payload
            }
        },
        [setCandidateProfilesData]: (state, {payload}) => {
            return {
                ...state,
                sources:{
                    ...state.sources,
                    list:payload
                }
            }
        },
        [setSourceLoading]: (state, {payload}) => {
            return {
                ...state,
                sources:{
                    ...state.sources,
                    isLoading:payload
                }
            }
        }
    },
    INITIAL_STATE

);



