import React, { Component } from 'react'
import DownloadPopop from '../../../component/DownloadFilePopup';
import { Button } from 'tn-components-library';
import { convertToFile } from "../../../utils"
import LineEllipsis from "../lineellipsis";
import PropTypes from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { getRoleExcel } from "../../../http/search";
import gtm from "../../../usagetracker/GTM"
import './styles.css'

export default class SelectedRole extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            downloadArray: [],
            downloadStatus: null
        }
    }

    handleClick = this.handleClick.bind(this);

    getGuid() {
        function S4() {
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        }
        return (S4() + "-" + S4() + "-4" + S4().substr(0, 3)).toLowerCase();
    }

    handleClick() {
        gtm.track(gtm.events.linkClick,
            {
                category: 'Download',
                action: "Multi-Role Export",
                label: this.props.selectedRole.length
            });
        let guid = this.getGuid();
        let that = this;
        let downloadArray = that.state.downloadArray.slice();
        downloadArray.unshift({ guid: guid, status: "DOWNLOAD_FILE_REQUEST" })
        that.setState({ downloadArray: downloadArray });
        let param = "curreny=usd&idxs=" + this.props.selectedRole.map(x => x.rnid).join(",");
        getRoleExcel(param).then(function (response) {
            let updatedArray = that.state.downloadArray.slice();
            updatedArray.forEach(x => {
                if (x.guid === guid) {
                    x.status = "DOWNLOAD_FILE_SUCCESS"
                }
            })
            let currentDate = new Date();
            let fileName = "CustomRoles_" + (currentDate.getMonth() + 1) + "-" + currentDate.getDate() + "-" + currentDate.getFullYear() + ".xlsx";
            convertToFile(response, fileName, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64");
            that.setState({ downloadArray: updatedArray });
            window.setTimeout(function () {
                updatedArray = that.state.downloadArray.slice().filter(x => x.guid !== guid);
                that.setState({ downloadArray: updatedArray });
            }, 10000)
        })
    }

    render() {
        let intl = this.context.intl;
        let totalDownload = this.state.downloadArray.length;
        return (
            <div className="row">
                <div className="col-md-12">
                    <span className="selected-role-heading padding-right-10">
                        {intl.formatMessage({ id: "selected_roles" })}</span>
                    <span className="selected-role-circle">{this.props.selectedRole.length}</span>
                    <div id="selectedHeight" className="selected-items">
                        <TransitionGroup className="function">
                            {this.props.selectedRole.map((role, i) => {
                                return (
                                    <CSSTransition key={role.rnid} timeout={400} classNames="fade">
                                        <div key={role.rnid} className="row multirole-row">
                                            <div className="col-md-7 multirole-title">
                                                <div>
                                                    <span className="ga ga-subtract01 pointer padding-right-10" onClick={() => this.props.removeRoleHandler(role)}></span>
                                                    <LineEllipsis container="#selectedHeight" onClick={() => this.props.toggleHandler(role)} className="underline" text={role.rolename}></LineEllipsis>
                                                </div>
                                            </div>
                                            <div className="col-md-5 multirole-title">
                                                <LineEllipsis container="#selectedHeight" text={role.cityname}></LineEllipsis>
                                            </div>
                                        </div>
                                    </CSSTransition>)
                            })}
                        </TransitionGroup>
                    </div>
                    <div className='export-button'>
                    <Button category='primary' label={intl.formatMessage({ id: "export_data" })} onClick={this.handleClick} />
                    </div>
                    <TransitionGroup className="download-popup">
                        {this.state.downloadArray.map((download, i) => {
                            let bottom = 30 + (70 * ((totalDownload - 1) - i));
                            const pStyle = {
                                bottom: bottom + 'px',
                                position: 'fixed',
                                left: '30px'
                            };
                            return (
                                <CSSTransition key={download.guid} timeout={2000} classNames="popup">
                                    <div style={pStyle}>
                                        <DownloadPopop reportDownloadingStatus={download.status} />
                                    </div>
                                </CSSTransition>)

                        })}
                    </TransitionGroup>
                </div>
            </div>
        )
    }
}

SelectedRole.contextTypes = {
    intl: PropTypes.object.isRequired
}


