import React from 'react';
import PropTypes from 'prop-types';
import { TalentNeuronLogo } from 'tn-components-library'
import "./styles.css"


const PDFTitle = (props) => {
    
    return (<div className="title-container">
        <div className="report-appname">
            <span className="bold501">TalentNeuron</span> {`– ${props.appName} Report`}
        </div>
        <div className="report-title">
            {props.title}
        </div>
        <div id="report-desc">
            {props.description}
        </div>
    </div>);
}

PDFTitle.defaultProps = {
    appName: 'Application Name',
    title: 'Report Title',
    description: 'Report Description'
}

PDFTitle.propTypes = {
    appName: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string
}


const  PDFHeader = (props) => {
    return (
        <div>
                <div className="pdf-header">
                    <div className="pull-left">
                        <span className="bold501">TalentNeuron</span> {`– ${props.appName} Report`}
                    </div>
                    <div className="pull-right">{props.title}</div>
                </div>
                <div className="pdf-result-browser">
                    {props.browserHref && <a className="result-browser-link" href={props.browserHref}>View results in browser</a>}
                    {props.tocHref &&
                        <span>
                            <a className="backtocontent" href={props.tocHref}>Back to Table of Contents</a>
                        </span>
                    }
                </div>
            </div>
    );
}

PDFHeader.defaultProps = {
    appName: 'Application Name',
    title: 'Report Title',
    browserHref: null,
    tocHref: null
}

PDFHeader.propTypes = {
    appName: PropTypes.string.isRequired,
    title: PropTypes.string,
    browserHref: PropTypes.string,
    tocHref: PropTypes.string
}


const PDFFooter = (props) => {
        let datetime = new Date()
        let currentDate = datetime.getDate() + " " + datetime.toLocaleString("en-us", { month: "short" }) + " " + datetime.getFullYear();
        return (
            <div>
                <div className="footer-continue-section">
                    <span className="footer-pagenumber">{props.pageNumber || ""}</span>
                    {props.continue &&
                        <span className="continued">Continued </span>
                    }
                </div>
                <div className="pdf-footer">
                    <div className="pull-left">
                        <div className="bold padding-top-10">{props.title}</div>
                        <div>©{datetime.getFullYear()} TalentNeuron. All rights reserved.</div>
                        <div className="bold padding-top-5">{currentDate}</div>
                    </div>
                    <div className="pull-right gartner-brand"><TalentNeuronLogo className="tnp-nav-top-logo" /></div>
                </div >
            </div>
        );
}

PDFFooter.defaultProps = {
    pageNumber: null,
    title: 'Report Title',
    continue: false
}

PDFFooter.propTypes = {
    pageNumber: PropTypes.number,
    title: PropTypes.string,
    continue: PropTypes.bool
}

class PDFPage extends React.Component{
    render(){
        return (
            <div className="report">
                {this.props.coverPage ? <PDFTitle {...this.props} />  : <PDFHeader {...this.props} /> }
                <div className={`main-container ${this.props.coverPage ? 'cover' : ''}page-height`}>
                    {this.props.children}
                </div>
                <PDFFooter {...this.props} />
            </div>
        );
    }
}


PDFPage.defaultProps = {
    coverPage : false,
    title: 'Report Title',
    linkToTOC: false,
    continue: false
}

PDFPage.propTypes = {
    coverPage : PropTypes.bool,
    title: PropTypes.string,
    linkToTOC: PropTypes.bool,
    continue: PropTypes.bool
}

export default PDFPage;