import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import {Flyout} from 'tn-components-library'
import Details from "./details"

class RoleDetails extends Component {

    constructor(props, context) {
        super(props, context);
        this.flyoutPrimary = null;
        this.toggleFlyout = this.toggleFlyout.bind(this);
        this.flyoutStateChange = this.flyoutStateChange.bind(this);
        this.toggleOverlayClick = this.toggleOverlayClick.bind(this)
        this.state = {
            data: {},
            isFlyoutOpen: false
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.flyoutState !== this.props.flyoutState) {
            this.setState({ isFlyoutOpen: nextProps.flyoutState })
        }
    }

    PrimaryFlyOut() {
        if (this.state.isFlyoutOpen) {
            document.body.setAttribute("style", "overflow: hidden;");
        }else{
            document.body.setAttribute("style", "overflow-y: scroll;");
        }
        return (createPortal(<div className= 'flyoutmenuprimary'>
        <Flyout  width="650px"  open={this.state.isFlyoutOpen} onClose={this.toggleOverlayClick} >
            <div className='bm-menu'>
                <div className='bm-item-list'>
                    <div className='bm-item'>
                    {open ? this.PrimaryFlyoutContent() : null}
                    </div>
                </div>
            </div>
        </Flyout>
        </div>, this.flyoutPrimary));
    }

    componentDidMount() {
        this.flyoutPrimary = document.getElementById("flyouthelp");
    }
    

    toggleOverlayClick() {
        this.setState({
            isFlyoutOpen: false,
        });
        if (typeof (this.props.toggleHandler) === "function") {
            this.props.toggleHandler();
        }
    }

    flyoutStateChange(state) {
        if (!state.isOpen) {
            document.getElementsByClassName('flyoutmenuprimary')[0].style.boxShadow = "0 0 0";
            if (this.state.isFlyoutOpen) {
                this.setState({
                    isFlyoutOpen: false
                })
                if (typeof (this.props.toggleHandler) === "function") {
                    this.props.toggleHandler();
                }
            }
        }
    }

    toggleFlyout() {
        this.setState({
            isFlyoutOpen: !this.state.isFlyoutOpen,
        });
    }

    PrimaryFlyoutContent() {
        return (<Details dashboardView={this.props.dashboardView} data={this.props.data} toggleFlyout={this.props.toggleHandler}></Details>)
    }

    render() {
        return (this.flyoutPrimary ? this.PrimaryFlyOut() : null)
    }
}

RoleDetails.contextTypes = {
    intl: PropTypes.object.isRequired
}

export default RoleDetails;