import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Typography } from 'tn-components-library';
import CircularProgressBar from "./../CircularProgressBar";
import "./styles.css";
import { CSSTransition } from 'react-transition-group';

export default class DownloadPopop extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			status: null,
			message: "",
			header: ",",
			className: "hide",
			percentage: 0
		};
		this.handleClick = this.handleClick.bind(this);
	}

	timer = null;

	handleClick(key, column) {
		this.setState({
			show: false,
			percentage: 100,
			status: null
		})
	}

	getState(state) {
		switch (state) {
			case "DOWNLOAD_FILE_REQUEST":
				this.timer = setInterval(this.progress, 200);
				return {
					show: true,
					status: state,
					className: "download-report-box progress",
					message: this.context.intl.formatMessage({ id: 'exporting_data' }),
					percentage: 0
				}
			case "DOWNLOAD_FILE_SUCCESS":
				clearInterval(this.timer);
				return {
					show: true,
					status: state,
					className: "download-report-box success",
					message: this.context.intl.formatMessage({ id: 'export_complete' }),
					percentage: 100
				}
			case "DOWNLOAD_FILE_ERROR":
				return {
					show: true,
					status: state,
					className: "download-report-box failed",
					message: this.context.intl.formatMessage({ id: 'export_failed' }),
					percentage: 0
				}
			case "DOWNLOAD_FILE_COMPLETE":
				return {
					percentage: 100,
					status: state
				}
			default:
				return { percentage: 0 }
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.reportDownloadingStatus !== this.props.reportDownloadingStatus)
			this.setState(this.getState(nextProps.reportDownloadingStatus))
	}

	componentDidMount() {
		let status = this.props.reportDownloadingStatus
		if (status != null) {
			this.setState(this.getState(status))
		}
	}

	componentWillUnmount() {
		clearInterval(this.timer);
	}

	progress = () => {
		const { percentage } = this.state;
		this.setState({ percentage: percentage >= 95 ? 95 : percentage + 5 });
	};

	render() {
		return (
			<div>
				<CSSTransition
					in={this.state.show}
					timeout={2000}
					classNames="popup"
					unmountOnExit>
					<div className="download-report-container">
						<div className={this.state.className}>
							<Typography category="heading4" value={this.state.message} />
							<div className="download-report-align">
								{this.state.status !== 'DOWNLOAD_FILE_ERROR' &&
									<CircularProgressBar
										strokeWidth="5"
										sqSize="34"
										percentage={this.state.percentage} />
								}
								<i onClick={this.handleClick} className={"ga ga-close01"}></i>
							</div>
						</div>
					</div>
				</CSSTransition>
			</div>
		);
	}
}

DownloadPopop.contextTypes = {
	intl: PropTypes.object.isRequired
}
