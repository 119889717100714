import Config from '../profiles';
import http from './http'

const searchAPI = (queryString = "") => {
    return new Promise((resolve, reject) => {
        http.get(Config.serviceURL + "/v1/cr/autosearch/roledata?" + queryString)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });

};

const getRoleExcel = (queryString) => {
    return new Promise((resolve, reject) => {
        let options = {
            method: 'GET',
            responseType: 'blob',
            headers: {
                'Accept': 'application/vnd.ms-excel',
                'Content-type': 'application/vnd.ms-excel'},
                url: Config.serviceURL + "/v1/cr/export/download/excel?" + queryString
        };
        http(options)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });

};

const getRoleDetail = (queryString) => {
    return new Promise((resolve, reject) => {
        http.get(Config.serviceURL + "/v1/cr/jobrole/data?" + queryString)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });

};

const uploadRoles = (formData) => {
    return new Promise((resolve, reject)=> {
        http.post(Config.serviceURL + "/v1/cr/roles/", formData).then((response)=> {
            resolve(response.data)
        })
        .catch((error) => {
            reject(error);
        });
    })
}

const deleteCustomRoles = (id) => {
    return new Promise((resolve, reject)=> {
        http.delete(Config.serviceURL + `/v1/cr/roles/${id}`).then((response)=> {
            resolve(response.data)
        })
        .catch((error) => {
            reject(error);
        });
    })
}

const getnotificationbydate = (currentDate) => {
    return new Promise((resolve, reject) => {
        http.get(Config.getAdminServiceURL + `/v1/cms/getnotificationbydate?date=${currentDate}`).then((response) => {
            resolve(response.data)
        })
        .catch((error) => {
            reject(error);
        })
    })
}

const getRecentSearches = () => {
    return new Promise((resolve, reject) => {
      http.get( `${Config.getAdminServiceURL}/tnrecentsearch/`).then((res) => {
        if (res?.data?.isError) {
          resolve([])
          return
        }
        resolve(res?.data?.data?.tnrecentsearch || [])
      })
    })
}

const recentSearchTracking = (widgetDetails) => {
    return new Promise((resolve, reject) => {
        http.post(`${Config.getAdminServiceURL}/tnrecentsearch/`, widgetDetails)
            .then((response) => {
                resolve(response.data)
            })
            .catch((err) => {
                console.log(err)
                reject(err)
              })
    })
}

export { searchAPI, getRoleExcel, getRoleDetail, uploadRoles, deleteCustomRoles, getnotificationbydate, getRecentSearches, recentSearchTracking }