import http from './http'
import Config from '../profiles';
import KeyCloakAuthInstance from '../keyAuth'

export const getMimicClientList = (term) =>
     http
        .get(Config.getAdminServiceURL + '/v1/mimicclient?term=' + term)
        .then((response) => response.data.data)

export const beginClientMimic = (clientId) => 
     http
        .post(Config.getAdminServiceURL + '/v1/mimicclient?clientId=' + clientId)
        .then((response) => response.data)
    .catch((e) => e)
        
export const removeUserFromCache = (userEmail) => http.delete(Config.serviceURL + '/v1/cr/cache/remove?key=getuserdetails_' + userEmail)
      .then(response => response.data)
      .catch(e => {console.error(e)});

export const handleEndSession = async () =>
      http
        .delete(Config.getAdminServiceURL + "/v1/mimicclient")
        .then((response) => {
          if (response.data.isError) {
            console.log("End mimic client session API Failed - IsError is true")
          return
          }
          KeyCloakAuthInstance.logout()
        })
        .catch((error) => {
        console.log("End mimic client session API Failed")
        console.log(error)
        })
  

