import React, { Component } from "react";
import PropTypes from 'prop-types';
import LineEllipsis from "../../container/home/lineellipsis";


class TextColumns extends Component {

	render() {
        if (this.props.data.length == 0) return null;
        
        let limitPerColumn = Math.ceil(this.props.data.length/this.props.noOfColumns);
        let gridColumn = Math.floor(12/this.props.noOfColumns);
        let allData = this.props.data.slice();
        let groupedData = [];

        if(this.props.orientation == "horizontal"){
            while(allData.length > 0) groupedData.push(allData.splice(0, this.props.noOfColumns));
        }
        else{
            for(let i=0; i<limitPerColumn; i++){
                let tempArray = []
                for(let n=0; n<this.props.noOfColumns; n++){
                    if(allData[i+(limitPerColumn*n)]){
                        tempArray.push(allData[i+(limitPerColumn*n)])
                    } 
                }
                groupedData.push(tempArray)
            }
        }

		return groupedData.map((value,idx) => <RowItem  key={idx} data={value} gridColumn={gridColumn}/>)
	}
}


const RowItem = (props) => {
    return (
        <div className="row row-item">{props.data.map((value,idx) => {
            return (<LineEllipsis key={idx} linesNo={1} className={`col-xs-${props.gridColumn}`} text={value} />)}) }</div>
    )
}

TextColumns.propTypes = {
    noOfColumns: PropTypes.number, 
    orientation : PropTypes.oneOf(['vertical', 'horizontal']),
}

TextColumns.defaultProps = {
    noOfColumns: 2, 
    orientation : "vertical"
};


export default TextColumns