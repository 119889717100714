import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { StickyContainer, Sticky } from 'react-sticky';
import { Typography, Modal, Button } from 'tn-components-library'
import { Checkbox, SearchGrid } from 'talentneuron---ui-components-library';
import LineEllipsis from "./lineellipsis"
import './styles.css'
import { searchAPI, getRoleDetail, uploadRoles, deleteCustomRoles } from "./../../http/search"
import { getDeleteRolePermission, getUploadRolePermission, isAbsoluteEmpty } from "./../../utils";
import PulseLoader from "./../../component/PulseLoader";
import CustomNoData from './nodatacomponent/nodata';
import CustomNoMatch from './nodatacomponent/nomatch';
import RoleDetails from "./roledetails";
import SelectedRole from "./selectedrole"
import gtm from "../../usagetracker/GTM"
class Home extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      gridData: [],
      country: [],
      isLoading: true,
      selectedRoleId: [],
      allData: [],
      downloadStatus: null,
      isFlyoutOpen: false,
      roledetails: {},
      hideCountry: false,
      showModal: false,
      isUploadSuccess: false,
      uploadFile: {
        name: '',
        file: null
      },
      uploadErrorMsg: null,
      isDeleteModalOpen: false,
      selectedCustomRole: null,
      isDeleteCustomRoleSuccess: false,
      showDeleteConfirmModal: false,
      showDisclaimerModal: false,
      file: null
    }
    gtm.init(window.customrole.UserContext);
    this.inputFileRef = React.createRef();
  }
  toggleFlyout = this.toggleFlyout.bind(this);
  selectRole = this.selectRole.bind(this);
  handleResize = this.handleResize.bind(this);
  handleClickUpload = this.handleClickUpload.bind(this);
  handleClickUploadDocument = this.handleClickUploadDocument.bind(this);
  handleClickDelete = this.handleClickDelete.bind(this);
  deleteCustomRole = this.deleteCustomRole.bind(this);

  deleteModalClose = this.deleteModalClose.bind(this);
  onModalClose = this.onModalClose.bind(this);
  onDesclaimerModalClose = this.onDesclaimerModalClose.bind(this);
  handleClickOk = this.handleClickOk.bind(this)
  roleNameSearchParam = ""
  citySearchParam = ""
  sortDirection = 'desc'
  sortColumn = 'publisheddate'

  componentDidMount() {
    gtm.track(gtm.events.viewed,
      {
        category: 'Pageview',
        page: 'Search Custom Roles'
      });
    this.getCustomRoles();
    window.addEventListener("resize", this.handleResize);
    let roleBox = document.querySelector(".role-box-op");
    if (roleBox) {
      let totalWidth = document.body.offsetWidth;
      let gridBoxWidth = document.querySelector(".grid-box").clientWidth;
      let mainContainer = document.querySelector(".content-body .main-container");
      let offset = (totalWidth - mainContainer.offsetWidth) / 2;
      roleBox.style.width = totalWidth - ((gridBoxWidth * 66.6666) / 100) - offset - 20 + "px";
    }
    document.onclick = function (e) {
      window.setTimeout(function () {
        this.maintainDropdownState();
      }.bind(this), 1)
    }.bind(this)
  }

  handleResize() {
    this.updateFlyoutWidth(true);
  }

  updateFlyoutWidth(resize) {
    let roleBox = document.querySelector(".role-box");
    if (roleBox) {
      let totalWidth = document.body.offsetWidth;
      let gridBoxWidth = document.querySelector(".grid-box").clientWidth;
      let mainContainer = document.querySelector(".content-body .main-container");
      let offset = (totalWidth - mainContainer.offsetWidth) / 2;
      roleBox.style.width = totalWidth - gridBoxWidth - offset - 20 + "px";
      if (resize) {
        roleBox.style.transition = "none"
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  getCustomRoles() {
    let that = this;
    const isClientMimiced = window.customrole.UserContext.mimicclient
    const clientId = window.customrole.UserContext.clientid
    searchAPI(isClientMimiced? `clientId=${clientId}`: '').then(function (response) {
      if (response.data.length && response.data[0].countrydata) {
        let country = response.data[0].countrydata.map(x => {
          return {
            value: x.id,
            label: x.name,
            isChecked: false
          }
        })
        country.unshift({ value: -1, label: that.context.intl.formatMessage({ id: "all_country_package" }), isChecked: true })
        let data = response.data[0].jobroledata;
        data.forEach(x=>x.publisheddate=new Date(x.publisheddate));

        that.setState({
          isLoading: false,
          allData: data,
          gridData: data,
          country: country
        });
        if (response.data[0].jobroledata && response.data[0].jobroledata.length === 0) {
          gtm.track(gtm.events.search,
            {
              dimension7: "",
              dimension8: "",
              dimension9: "",
            });
        }
      } else {
        that.setState({
          isLoading: false,
          allData: [],
          gridData: [],
          country: []
        });
      }
    })
  }

  maintainDropdownState() {
    let dropdownRoot = document.querySelectorAll("table .Dropdown-control");
    if (dropdownRoot.length) {
      let selectedCountry = this.state.country.slice(0).filter(x => (x.isChecked === true && x.value != -1))
      if (selectedCountry.length !== 0) {
        dropdownRoot[0].classList.add("dropdown-changed");
      } else {
        dropdownRoot[0].classList.remove("dropdown-changed");
      }
    }
  }

  componentDidUpdate() {
    this.maintainDropdownState();
    let roleBox = document.querySelector(".role-box") || document.querySelector(".role-box-op");
    if (roleBox) {
      roleBox.style.transition = "width 0.4s linear 0s";
    }
  }

  changeHandler(name, id, checked) {
    let items = this.state.country.slice(0);
    items.forEach(item => {
      if (id == -1) {
        if (item.value == -1) {
          item.isChecked = checked;
        } else {
          item.isChecked = false;
        }
      } else {
        if (item.value == -1) {
          item.isChecked = false;
        } else if (item.value == id) {
          item.isChecked = checked;
        }
      }
    });

    if (items.filter(x => x.isChecked).length == 0) {
      items[0].isChecked = true;
    }

    this.setState({
      country: items
    })
    this.searchGrid();
  }

  renderDropdownOption(item) {
    return (
      
      <div key={item.value} className="Dropdown-option" >
        <Checkbox name={item.label} value={item.value} changeHandler={this.changeHandler.bind(this)} checked={item.isChecked} />
         </div>)
  }

  filterGrid(key, text) {
    if (key === 'rolename') {
      this.roleNameSearchParam = text.toLowerCase();
    }
    if (key === 'cityname') {
      this.citySearchParam = text.toLowerCase();
    }
    this.searchGrid();
  }

  searchGrid() {
    let data = this.state.allData.slice(0);
    let items = this.state.country.slice(0).filter(x => (x.isChecked === true && x.value != -1)).map(x => x.label);
    if (!isAbsoluteEmpty(this.roleNameSearchParam)) {
      data = data.filter(x => x.rolename.toLowerCase().includes(this.roleNameSearchParam));
    }
    if (!isAbsoluteEmpty(this.citySearchParam)) {
      data = data.filter(x => x.cityname.toLowerCase().includes(this.citySearchParam));
    }
    if (items.length > 0) {
      data = data.filter(x => items.indexOf(x.countryname) >= 0);
    }
    if (data.length === 0) {
      window.typingTimer = window.typingTimer || 0;
      clearTimeout(window.typingTimer);
      window.typingTimer = setTimeout(function () {
        gtm.track(gtm.events.search,
          {
            dimension7: this.state.country.filter(x => x.isChecked).map(x => x.label).join(","),
            dimension8: this.citySearchParam,
            dimension9: this.roleNameSearchParam,
          });
      }.bind(this), 2000);
    }
    this.setState({
      gridData: data
    });
  }

  renderRoleTitle(props) {    
    return (<div className="underline">     
      {
        props.rolename.length > 90 &&
        <LineEllipsis container=".content-body tbody" text={props.rolename} onClick={() => this.toggleFlyout(props)}></LineEllipsis>
      }
      {
        props.rolename.length <= 90 &&
        <span onClick={() => this.toggleFlyout(props)}>{props.rolename}</span>
      }
    </div>)
  }
  toggleFlyout(props) {
    if (props) {
      getRoleDetail("roleidx=" + props.rnid).then(function (response) {
        gtm.track(gtm.events.linkClick,
          {
            category: 'Custom Roles Search',
            action: "Search Validation",
            dimension9: props.rolename
          });
        if (response.data && response.data.length) {
          response.data[0].title = props.rolename;
          this.setState({ roledetails: response.data[0] })
        }
      }.bind(this))
    }
    this.setState({ isFlyoutOpen: !this.state.isFlyoutOpen, roledetails: {} });
  }

  selectRole(props) {
    let selectedRoleId, initialRole;
    selectedRoleId = initialRole = this.state.selectedRoleId.slice();
    if (selectedRoleId.map(x => x.rnid).indexOf(props.rnid) >= 0) {
      selectedRoleId = selectedRoleId.filter(x => x.rnid !== props.rnid)
    } else {
      selectedRoleId.unshift(props);
    }

    if ((selectedRoleId.length === 1 && this.state.selectedRoleId.length === 0) || (selectedRoleId.length === 0 && this.state.selectedRoleId.length === 1)) {
      window.setTimeout(function () {
        this.forceUpdate();
      }.bind(this), 500);
    }
    this.setState({ selectedRoleId: selectedRoleId, hideCountry: selectedRoleId.length > 0 });
  }

  handleClickDelete (data) {
    this.setState({
      isDeleteModalOpen: true,
      selectedCustomRole: data
    })
  }

    deleteCustomRole () {
    const deletrole = this.state.selectedCustomRole
    deleteCustomRoles(deletrole.rnid).then(async(response) => {
    if (response.statusCode == 200 && !response.isError && response.data) {
        this.setState({
          isDeleteCustomRoleSuccess: true,
          showDeleteConfirmModal: true
        })
       await this.getCustomRoles();
      }
      else {
        this.setState({
          isDeleteCustomRoleSuccess: false,
          showDeleteConfirmModal: true
        })
      }
    }).catch(error => {
      console.log("Error while deleting custom role");
    });
  }

  deleteModalClose () {
    this.setState({
      isDeleteModalOpen: false,
      selectedCustomRole: null,
      isDeleteCustomRoleSuccess: false,
      showDeleteConfirmModal: false
    })
  }


  renderCityName(props) {
    const isDeleteroleIsEnabled = getDeleteRolePermission()
    return (<div className='render-city-container'>
      {
        props.cityname.length > 30 &&
        <LineEllipsis container=".content-body tbody" text={props.cityname}></LineEllipsis>
      }
      {
        props.cityname.length <= 30 &&
        <span>{props.cityname}</span>
      }
      {isDeleteroleIsEnabled && <Button category='negative'  label='Delete' className='delete-btn' onClick={()=> this.handleClickDelete(props)}/>}
    </div>)
  }

  renderPublishedDate(props) {  
    let selectedRoleId = this.state.selectedRoleId.slice(0);
    let selectIconClass = selectedRoleId.map(x => x.rnid).indexOf(props.rnid) >= 0 ? 'ga ga-check01 pointer' : `ga ga-add01 pointer`;       
    let dt =  new Date(props.publisheddate).toDateString('MM-DD-YYYY').split(' ');
      return (<div>  
        <span onClick={() => this.selectRole(props)} className={selectIconClass}></span>                
        <span>{dt[1] + ' ' + dt[2] + ', ' + dt[3]}</span>
    </div>)
  }


  tableTalentHeaderDefinition = () => {
    let columns = [];
    let selectedCountry = this.state.country.filter(x => (x.isChecked === true && x.value != -1));
    let placeHolder = "Countries (" + (selectedCountry.length === 0 ? "All)" : (selectedCountry.length + ")"))
    let countryColumn = {
      colKey: 'countryname',
      type: 'alpha',
      className: 'col-md-3 colHead-edits',
      header: {
        type: "dropdown",
        placeholder: placeHolder,
        option: { items: this.state.country },
        renderer: this.renderDropdownOption.bind(this)
      }
    }
    
    columns.push(
      {
        colKey: 'publisheddate',
        type: 'date',
        className: 'col-md-2 publish',
        renderer: this.renderPublishedDate.bind(this),
        displayName:'Published'
        
      },
      {
        colKey: 'rolename',
        type: 'alpha',
        className: `colHead-edits pointer col-md-${this.state.hideCountry ? '7' : '4'}`,
        renderer: this.renderRoleTitle.bind(this),
        header: { type: "input", placeholder: "Search by role name", changeHandler: this.filterGrid.bind(this) }
      },
      {
        colKey: 'cityname',
        type: 'alpha',
        className: 'col-md-3 primary colHead-edits',
        renderer: this.renderCityName.bind(this),
        header: { type: "input", placeholder: "Search by location", changeHandler: this.filterGrid.bind(this) }
      })
      if(this.state.hideCountry === false ){
        columns.splice(2, 0, countryColumn);
      }
    return columns;
  }

  onGridSortCallBack(sortColumn, sortDirection) {
    this.sortColumn = sortColumn;
    this.sortDirection = sortDirection;
  }

  handleClickUpload (value) {
    this.setState({
      showModal: value,
      isUploadSuccess: false
    })
  }

  handleClickUploadDocument(e) {    
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    this.setState({ file: formData, showDisclaimerModal: true });
    e.target.value = null;   
  }

  onDesclaimerModalClose(){
    this.setState({showDisclaimerModal: false})
  }

  handleClickOk(){
   this.setState({showDisclaimerModal: false})
   const fullNameWithExtn = this.state.file.get('file').name.split('.');
   uploadRoles(this.state.file).then(response => {
     if (response.statusCode == 200 && !response.isError) {
       if(response.data.error) {
         this.setState({
           uploadFile: {
             name: fullNameWithExtn[0],
             file: this.state.file.get('file')
           },
           showModal: true,
           isUploadSuccess: false,
           uploadErrorMsg: response.data.message
         })
       } else {
         this.setState({
           uploadFile: {
             name: fullNameWithExtn[0],
             file: this.state.file.get('file')
           },
           showModal: true,
           isUploadSuccess: true
         })
         this.getCustomRoles();
       }
     }
     else {
       this.setState({
         uploadFile: {
           name: fullNameWithExtn[0],
           file: this.state.file.get('file')
         },
         showModal: true,
         isUploadSuccess: false,
         uploadErrorMsg: null
       })
     }
   }).catch(error => {
     console.log("Error while uploading custom role");
   });
  }

  onModalClose(){
    this.setState({
        showModal : false,
        uploadErrorMsg: null,
        uploadFile: {
          name: '',
          file: null,
          isUploadSuccess: false
        }
    });
}

  onclickUpload(event) {
    event.preventDefault();
    if (this.inputFileRef.current) {
      this.inputFileRef.current.click()
    }
  }

  render() {
    let NoDataCompoent = this.state.allData.length === 0 ? CustomNoData : CustomNoMatch;
    let intl = this.context.intl;
    let griddata = this.state.gridData;
    let exportOn = this.state.selectedRoleId.length > 0;
    const uploadFile = this.state.uploadFile
    const selectedCustomRole = this.state.selectedCustomRole
    const isUploadRoleIsEnabled = getUploadRolePermission()
    const showDeleteConfirmModal = this.state.showDeleteConfirmModal
    const client_name = window.customrole.UserContext.clientname
    return (
      <div>
        <RoleDetails data={this.state.roledetails} flyoutState={this.state.isFlyoutOpen} toggleHandler={this.toggleFlyout} />
        <StickyContainer className="sticky-container">
          <div className={"separate-header-from-main-container " + (this.props.hideHeader ? "hidden" : "visible")}>
            <Sticky topOffset={10}>
              {({
                style,
              }) => {
                if (style.hasOwnProperty("transform")) {
                  delete style.transform;
                }
                let newStyle = {
                  ...style,
                  zIndex: 15,
                  backgroundColor: "#ffffff"
                };

                // let refineStyle = isSticky == true ? refineStyle = 'sticky-true' : 'sticky-false'

                return (
                  <header id="advHeader" style={newStyle}>
                    <div className="main-nav">
                      <div className="main-container">
                        <div className={"nav-left-section"}>
                        <Typography category='heading2' className="CRHeader" value={intl.formatMessage({ id: "custom_roles" })} />
                        </div>
                        {isUploadRoleIsEnabled && <div className={"nav-right-section"}>
                          <Button category='primary' label='Upload' onClick={this.onclickUpload.bind(this)}/>
                          <input type="file" id="file-upload" accept=".xlsx" ref={this.inputFileRef}  onChange={event => this.handleClickUploadDocument(event)}/>
                        </div>}
                      </div>
                    </div>
                  </header>
                );
              }}
            </Sticky>
          </div>
          <div className="content-body">
            <div className="main-container"><div className="row">
                      <div className={exportOn ? "col-md-8 grid-box" : "col-md-12 grid-box"}>
                        {this.state.isLoading === true && <PulseLoader />}
                        {this.state.isLoading === false &&
                        <SearchGrid className="data-table col-md-12"
                                    positionTitleAbove="Body"
                                    columnDefs={this.tableTalentHeaderDefinition()}
                                    dataSet={griddata}
                                    role={this.state.selectedRoleId}
                                    rowId='rnid'
                                    initialSortColumn={this.sortColumn}
                                    onSortCallBack={this.onGridSortCallBack.bind(this)}
                                    noDataComponent={NoDataCompoent}
                                    initialSortDirection={this.sortDirection}>
                        </SearchGrid>
                        }
                      </div>
                      {/* <div className="opacity0 width0 role-box"> */}
                      <div className={exportOn ? "role-box" : "role-box-op"}>
                        <SelectedRole toggleHandler={this.toggleFlyout} selectedRole={this.state.selectedRoleId} removeRoleHandler={this.selectRole}></SelectedRole>
                      </div>
                    </div>
            </div>
            {this.state.showDisclaimerModal && <Modal open={this.state.showDisclaimerModal} className='desclaimer-modal' size={'small'}>
            <div className='modal-title'>
              <div className="heading3" dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "disclaimer_title" })}} />
            </div>
            <div className="prompt-body">{intl.formatMessage({ id: 'disclaimer_content' })}</div>
                <div className="button-row">
                  <Button category="primary" className="pull-right" label={intl.formatMessage({ id: 'ok' })} onClick={this.handleClickOk} />
                  <Button category='ghost'  className="pull-right" type="button" label={intl.formatMessage({ id: 'cancel' })} onClick={this.onDesclaimerModalClose} />
                  
                </div>
              </Modal>}
            {(this.state.showModal) && <Modal open={this.state.showModal} size={'small'}>
              <div className='modal-title'>
                {this.state.uploadErrorMsg && !this.state.isUploadSuccess ?
                  <div className="heading4" dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "upload_custom_error_message" }, { fileName: uploadFile.name, error_message: this.state.uploadErrorMsg }) }} />
                  : <div className="heading4" dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: this.state.isUploadSuccess ? "upload_custom_role_success" : "upload_custom_role_failed" }, { fileName: uploadFile.name }) }} />}
              </div>
              <Button className="pull-right" category="ghost" label="Close" onClick={this.onModalClose} />
            </Modal>}
            {(this.state.isDeleteModalOpen && selectedCustomRole) && <Modal open={this.state.isDeleteModalOpen} size='small'>
            {showDeleteConfirmModal ?
              <>
                <div className="heading4" dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: this.state.isDeleteCustomRoleSuccess ? "delete_custom_role_success" : "delete_custom_role_failed" }, { custom_role: selectedCustomRole.rolename, client_name })}} />
                <div className="button-row">
                  <Button className="pull-right" category="ghost" label="Close" onClick={this.deleteModalClose} />
                </div>
              </> :
              <>
                <div className="heading3" dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "delete_custom_role" }, { custom_role: selectedCustomRole.rolename })}} />
                <div className="prompt-body">{`Are you sure you want to delete ${selectedCustomRole.rolename} for ${client_name}?`}</div>
                <div className="button-row">
                  <Button category='negative'  className="pull-right" type="button" label={intl.formatMessage({ id: 'delete' })} onClick={this.deleteCustomRole} />
                  <Button category="ghost" className="pull-right" label={intl.formatMessage({ id: 'cancel' })} onClick={this.deleteModalClose} />
                </div>
              </>
            }
          </Modal>}
          </div>
        </StickyContainer>
      </div>
    )
  }
}

Home.contextTypes = {
  intl: PropTypes.object.isRequired,
}




export default Home;
