import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Typography } from 'tn-components-library';
import './styles.css'

class NotEnoughData extends Component {

    render() {
        let intl = this.context.intl;
        return (
            <div className="text-center not-enough-data-center">
                <div className="not-enough-data-icon ga ga-search-analysis-01"></div>
                <Typography category='heading2' className="no-margin" value={intl.formatMessage({ id: "not_enough_data" })} />
            </div>
        );
    }
};

NotEnoughData.contextTypes = {
    intl: PropTypes.object.isRequired
}

export default NotEnoughData;