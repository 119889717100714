import KeyCloakAuthInstance from '../keyAuth';
import Config from '../profiles';
import http from './http'
import axios from 'axios';


const getAuthData = () => {
    return new Promise((resolve, reject) => {

        //Get User from windows -i.e. from Tomcat -> Index.jspx
        if (Config.getUserContext) {
            resolve(Config.getUserContext);
            return;
        }

        console.log("Get Plan Auth Data from Service URL");

        const plan = http.get(Config.currentOrigin + "/userdetails/plan");
        axios.all([
            plan
        ]).then(axios.spread((planresponse) => {
            if (planresponse.data.IsError) {
                console.log("Auth API Failed - IsError is true");
                reject(null);
            }
            if (!planresponse.data.Data[0]?.isactive) {
                KeyCloakAuthInstance.logout();
              	return;
            }
            resolve(planresponse.data.Data[0]);
        })).catch((error) => {
            console.log("Auth API Failed");
            console.log(error);
            reject(null);
        });
    });

};


export default { getAuthData }
