import {setAuth} from '../actions/auth';
import Config from '../profiles'
import { isAbsoluteEmpty } from "../utils"

const parseAuthResponse = (usercontext, store) => {
  let isAuthenticated = true;

  if (!usercontext) {
    console.log("Auth User Context is Empty");
    setAuthFailed(store);
    return;
  }

  if (usercontext.customroleenabled === false || isAbsoluteEmpty(usercontext.email)) {
    window.location.assign(Config.tnHomePageUrl);
  }

  const userDetails = {
    Email: usercontext.email,
    FirstName: usercontext.firstname,
    LastName: usercontext.lastname,
    Title: usercontext.title,
    ClientCode: usercontext.clientcode,
    ClientID: usercontext.clientid,
    ClientName: usercontext.clientname,
    locale: usercontext.locale,
    IsActive: usercontext.isactive,
    Tnr1xEnabled: usercontext.tnr1xenabled,
    Tnr2xEnabled: usercontext.tnr2xenabled,
    AdminEnabled: usercontext.adminenabled,
    tnp2xenabled: usercontext.tnp2xenabled,
    responsibilityIdsCSV: usercontext.responsibilityIdsCSV,
    personaID: usercontext.personaID
  };

  if (window.customrole.UserContext == null) {
    window.customrole.UserContext = usercontext;
  }

  const isAuthorized = true

  store.dispatch(setAuth({ loginURL: Config.loginURL, isAuthenticated, userDetails, isAuthorized }));
}

const setAuthFailed = (store) => {
  store.dispatch(setAuth({ isAuthenticated: false, loginURL: Config.loginURL }));
};

export default { parseAuthResponse, setAuthFailed };