import React, { Component } from 'react'
import configs from './config.json'
import KeyStats from '../../component/KeyStat';
import TopEmployers from '../../container/TopEmployers';
import Salary from '../../container/Salary';
import Skills from '../../container/Skills'
import GenderDiversity from '../../container/GenderDiversity'
import EthnicDiversity from '../../container/EthnicDiversity'
import TopIndustries from "../TopIndustries";
// import CandidateSources from '../../container/CandidateSources';
import Widget from "../../component/Widget";
import NotEnoughData from '../../container/home/nodatacomponent/notEnoughData';
//import MigratoryTrends from '../../container/MigratoryTrends';

    class WidgetsList extends Component {

    constructor(props){
        super(props)
    }

    genderEthnicDiversity () {
        if (this.props.ethnicdiversity ){
            return (
                <div className="diversity-widget">
                    {(this.props.intakeinformation && this.props.intakeinformation.countryCode == "US") &&
                    <div className="twin-widget-wrapper">
                        <div className="grid-one-col-left">
                            <EthnicDiversity countryCode={this.props.intakeinformation.countryCode} {...configs.Ethnicdiversity} data={this.props.ethnicdiversity}/>
                        </div>
                        <div className="grid-one-col-right">
                            <GenderDiversity countryCode={this.props.intakeinformation.countryCode} {...configs.Genderdiversity} data={this.props.genderdiversity}/>
                        </div>
                    </div>
                    }
                    {
                        (this.props.intakeinformation && this.props.intakeinformation.countryCode != "US") &&
                        <GenderDiversity
                            countryCode={this.props.intakeinformation.countryCode} {...configs.Genderdiversity}
                            data={this.props.genderdiversity}/>
                    }
                </div>
            )
        }else {
            return(
            <GenderDiversity {...configs.Genderdiversity} data={this.props.genderdiversity}/>
            )
        }

    }
    
    widgetList() {
        let others = this.props.industries? this.props.industries.filter(el => el.otherindustries).map(other => other.otherindustries): [];

        let countryCode = this.props.intakeinformation && this.props.intakeinformation.countryCode;

        switch (this.props.page) {
            case "Dashboard":
                return(
                    <div>
                        <KeyStats {...configs.keystats} data={this.props.keystats} />
                        <div className="twin-widget-wrapper">
                            <div className="grid-one-col-left">
                                <Salary {...configs.salary}   data={this.props.salary} defaultCurrency={this.props.currency}/>
                            </div>
                            <div className="grid-one-col-right">
                                <TopEmployers {...configs.topemployers} data={this.props.topemployers} />
                            </div>
                        </div>
                        <Skills {...configs.skills}   data={this.props.skills}/>
                        <TopIndustries {...configs.industries} data={this.props.industries} others={others}/>
                        {this.genderEthnicDiversity()}
                        {/* Commenting below line as CR inward Migratory trends will be on hold */}
                        {/* <MigratoryTrends {...configs.MigratoryTrends} data={this.props.migratorytrends}/> */}
                        {/* <CandidateSources {...configs.candidatesources}></CandidateSources> */}
                    </div>
                );
            case "ReportPage1":
                return(
                    <div>
                        <KeyStats {...configs.keystats} data={this.props.keystats} isReport={true}/>
                        <div className="twin-widget-wrapper">
                            <div className="grid-one-col-left">
                                <Salary {...configs.salary}   data={this.props.salary} defaultCurrency={this.props.currency} isReport={true}/>
                            </div>
                            <div className="grid-one-col-right">
                                <TopEmployers {...configs.topemployers} data={this.props.topemployers} isReport={true} />
                            </div>
                        </div>
                        <Skills {...configs.skills}   data={this.props.skills} isReport={true}/>
                    </div>
                );
            case "ReportPage2":
                return(
                    <div>
                        <TopIndustries {...configs.industries} data={this.props.industries} isReport={true}  others={others} />
                        { ( countryCode && countryCode == "US") && 
                        <div className="twin-widget-wrapper report-diversity-widget">
                            <div className="grid-one-col-left">
                                <EthnicDiversity {...configs.Ethnicdiversity} countryCode={countryCode} data={this.props.ethnicdiversity} isReport={true}  />
                            </div>
                            <div className="grid-one-col-right">
                                <GenderDiversity {...configs.Genderdiversity} countryCode={countryCode} data={this.props.genderdiversity} isReport={true}  />
                            </div>
                        </div>}

                        {(countryCode && countryCode != "US") && 
                            <GenderDiversity {...configs.Genderdiversity} countryCode={countryCode}  data={this.props.genderdiversity} isReport={true} />
                        }                        
                    </div>
                );
            default:
                return [];
        }
    }
    render() {
        return (  
                <div>{this.widgetList()}</div>
        )
    }
}

export default WidgetsList;