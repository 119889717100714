import {handleActions} from 'redux-actions'

import {
    setCurrencylist,
    setSelected
} from '../actions/currency'


export const INITIAL_STATE = {
    all :null,
   selected: {"code":"USD","name":"US Dollar (USD)","symbol":"&#x24;","rate":1.0}
};

export default handleActions(
    {
        [setCurrencylist]: (state, {payload}) => {
            return {
                ...state,
                all:payload
            }
        },
        [setSelected]: (state, {payload}) => {
            return {
                ...state,
                selected:payload
            }
        },
    },
    INITIAL_STATE

);

