import React, { Component } from 'react';
import { Typography, Button } from 'tn-components-library'
import {withRouter, NavLink} from 'react-router-dom'
import './styles.css';
import PulseLoader from "../../../component/PulseLoader";
import gtm from "../../../usagetracker/GTM";
import PropTypes from 'prop-types';

const SkillsAnalyzedList = ({ skills }) => {
    return (
        <ul>
            {skills.map((skill, index) =>
                <SkillsAnalyzedItems
                    key={index}
                    name={skill.skillname} />
            )}
        </ul>
    )
}

const SkillsAnalyzedItems = ({ name }) => {
    return (
        <li>
            {name}
        </li>
    )
}

export default class Details extends Component {
    constructor(props, context) {
        super(props, context);
    }


    gtmClick(e) {
        e.preventDefault();
        gtm.track(gtm.events.viewResult,
            {
                label: "TN Custom Role Validation ("+this.props.data.jobroledescription["0"].jobRoleID+")"
            }
        );
    }

    componentDidMount() {
        document.getElementsByClassName("bm-item")[0];
    }

    render() {
        const ViewResults = withRouter(({history}, props) => (
            <NavLink
                to={{
                    pathname: "/dashboard",
                    search: "role="+ this.props.data.jobroledescription[0].idx
                }}
            >
            {intl.formatMessage({ id: "view_results" })}
            </NavLink>
        ));
        let data = this.props.data;
        let intl = this.context.intl;
        let dt = '';
        if (data.jobroledescription && data.jobroledescription.length > 0)
            dt = new Date(data.jobroledescription[0].publishedDate).toDateString('MM-DD-YYYY').split(' ');
        return (
            <div className="roles-flyout">
                {data && data.jobroledescription &&
                    <div>
                        {data.jobroledescription.map((jobroledescription, i) => {
                            return (<div key={jobroledescription.jobRoleID}>
                                {!this.props.dashboardView && <Button className="btn-view-results" category='primary' label={<ViewResults/>} onClick={this.gtmClick.bind(this)}></Button>}
                                <Typography category='heading3' value={data.title} />
                                <div className="jobrole-location">
                                    {jobroledescription.cityName + ' - ' + dt[1] + ' ' + dt[2] + ', ' + dt[3]}
                                </div>
                            </div>)
                        })}

                        {data.jobroleskills.length > 0 &&
                            <div className="skills-analyzed-container">
                                <div className="skills-analyzed-header">
                                    {
                                        intl.formatMessage({ id: "top_skills" })
                                    }
                                </div>
                                <SkillsAnalyzedList skills={data.jobroleskills} />
                            </div>
                        }
                        {data.jobroledescription.map((jobroledescription, i) => {
                            return (<div key={jobroledescription.jobRoleID} className="jobrole-body">
                                {jobroledescription.roleDescription}
                            </div>)
                        })}
                    </div>
                }
                {!data.jobroledescription &&
                    <PulseLoader />
                }
            </div>
        )
    }
}

Details.contextTypes = {
    intl: PropTypes.object.isRequired
}


