import React, { Component } from "react";
import Widget from "../../component/Widget";
import PropTypes from 'prop-types';
import TextColumns from "../../component/TextColumns"
import './style.css'

class TopEmployers extends Component {
    
    render() {
        if(!this.props.data){
            return <Widget {...this.props} />
        }
        
        return (
            <Widget  {...this.props}>
                <div className="top-employers">
                    <TextColumns data={this.props.data} noOfColumns={this.props.isReport ? 2 : 3}  />
                </div>
            </Widget>
        );
    }
}

TopEmployers.contextTypes = {
    intl: PropTypes.object.isRequired
}

export default TopEmployers;