import React from 'react'
import PropTypes from 'prop-types';
import viewResultsRole from "../../http/dashboard";
import { StickyContainer, Sticky } from 'react-sticky';
import { Typography } from 'tn-components-library';
import { getParameterByName, convertToFile } from '../../utils'
import WidgetsList from '../widgets/widgetsList';
import CurrencyList from "../currencyList";
import RoleDetails from "../home/roledetails";
import {setDashboardData} from '../../actions/dashboard'
import {connect} from 'react-redux'
import {setSelected} from "../../actions/currency";
import {getRoleDetail} from "../../http/search";
import gtm from "../../usagetracker/GTM";
import {IntakeViewButton} from "../../component/IntakeViewButton";
import PopOver from "../PopOver"
import ClickOutHandler from 'react-onclickout';
import ReactTooltip from 'react-tooltip';
import { exportpdf } from "../../http/export"
import Config from '../../profiles';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import DownloadPopop from '../../component/DownloadFilePopup';
import './style.css'
import {candidateProfiles} from '../../actions/dashboard'
import KeyCloakAuthInstance from '../../keyAuth';

class Dasboard extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            data: null,
            roledetails: {},
            isFlyoutOpen: false,
            showPopover : false,
            downloadArray : []
        };
    }
    toggleFlyout = this.toggleFlyout.bind(this);

    togglePopover() {
        this.setState({
            showPopover: !this.state.showPopover
        })
    }

    onClickOut(e) {
        if(this.state.showPopover){
            if(e.target.classList.contains("ga-download")){
                return;
            }
            this.setState({
                showPopover: false
            });
        }
    }
    predownload() {
        let guid = this.getGuid();
        let downloadArray = this.state.downloadArray.slice();
        downloadArray.unshift({ guid: guid, status: "DOWNLOAD_FILE_REQUEST" })
        this.setState({ downloadArray: downloadArray });
        return guid;
    }

    postdownload(guid, isFailed) {
        let updatedArray = this.state.downloadArray.slice();
        updatedArray.forEach(x => {
            if (x.guid === guid) {
                x.status = isFailed ? 'DOWNLOAD_FILE_ERROR' : "DOWNLOAD_FILE_SUCCESS"
            }
        })
        this.setState({ downloadArray: updatedArray });
        window.setTimeout(function () {
            updatedArray = this.state.downloadArray.slice().filter(x => x.guid !== guid);
            this.setState({ downloadArray: updatedArray });
        }.bind(this), 10000);
    }
    getGuid() {
        function S4() {
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        }
        return (S4() + "-" + S4() + "-4" + S4().substr(0, 3)).toLowerCase();
    }

    downloadPdf(title, desc) {
        this.setState({
            showPopover: false
        })
        
        let currency  = this.context.store.getState().currency.selected
        this.time = 500;
        let request = {};
        let roleID = Number(getParameterByName("role"));
        let name  = encodeURIComponent(currency.name)
        let description = encodeURIComponent(desc.replace(/\n/g,' ').trim())
        let accessToken = KeyCloakAuthInstance.getAccessToken();

        request.url = Config.currentOrigin +  `?token=${accessToken}` + "#/report?role=" + roleID + "&title="+title + "&cur="+currency.code + "&name="+name + "&description="+description
        request.token = accessToken;
        var config = {
            headers: {
                'Accept': 'application/pdf'
            },
            responseType: 'blob'
        };
        let that = this;
        let guid = that.predownload();
        exportpdf(request, config).then(function (response) {
            let currentDate = new Date();
            let fileName = "CustomRoles_results_" + (currentDate.getMonth() + 1) + "-" + currentDate.getDate() + "-" + currentDate.getFullYear() + ".pdf";
            convertToFile(response.data, fileName, "application/pdf");
            that.postdownload(guid);
        }, () => {that.postdownload(guid, true);})
    }

    toggleFlyout(props) {
        if (this.props) {
            getRoleDetail( this.props.location.search.replace( "?role=" ,"roleidx=")).then(function (response) {
                gtm.track(gtm.events.linkClick,
                    {
                        category: 'Custom Roles Search',
                        action: "Search Validation",
                        dimension9: this.state.data.intakeinformation.rolename
                    });
                if (response.data && response.data.length) {
                    response.data[0].title =  this.state.data.intakeinformation.rolename;
                    response.data[0].intakeinformation = this.state.data.intakeinformation;
                    this.setState({ roledetails: response.data[0]})
                }
            }.bind(this))
        }
        this.setState({ isFlyoutOpen: !this.state.isFlyoutOpen, roledetails: {} });
    }
    //Commenting below line as CR inward Migratory trends will be on hold
    // componentWillMount(){
    //     this.loadGoogleMaps();
    // }

    // loadGoogleMaps() {
    //     if (window.google) 
    //         return false;

    //     const script = document.createElement('script');
    //     script.src = `https://maps.googleapis.com/maps/api/js?key=${Config.googleMapsApiKey}`;
    //     document.body.appendChild(script);
    // }

    loadData(roleID){

        
        Promise.all([
            dashboardAPI(roleID)
        ]).then(values => {
            let responses = {...values[0]};
            if (responses) {
                this.setState({ data: responses }, ()=>{
                    (this.state.data && this.state.data.currency)  && this.props.doSetSelected(this.state.data.currency);
                    this.props.doSetDashboardData(this.state.data)
                })
            } else {
                return -1
            }
        }).catch(error => {
            console.log(error);
        });
    }

    componentDidMount() {
        let roleID = Number(getParameterByName("role"))
        this.loadData(roleID);
        this.props.doCallCandidateProfiles(roleID);
    }

    render() {
        let intl = this.context.intl;
        let roleDetails = this.state.data;
        let roleNameAndPlace = roleDetails && roleDetails.intakeinformation ? roleDetails.intakeinformation.rolename + ", " + roleDetails.intakeinformation.location : ''
        return (
            <div>
                {this.state.data && <StickyContainer className="sticky-container">
                    <RoleDetails dashboardView={true} data={this.state.roledetails} flyoutState={this.state.isFlyoutOpen} toggleHandler={this.toggleFlyout} />
                    <div className={"separate-header-from-main-container " + (this.props.hideHeader ? "hidden" : "visible")}>
                        <Sticky topOffset={10}>
                            {({
                                isSticky,
                                style,
                            }) => {
                                if (style.hasOwnProperty("transform")) {
                                    delete style.transform;
                                }
                                let newStyle = {
                                    ...style,
                                    zIndex: 15,
                                    backgroundColor: "#ffffff"
                                };
                                let refineStyle = isSticky ? 'sticky-true' : 'sticky-false'
                                return (
                                    <header id="advHeader" style={newStyle}>
                                        <div className="main-nav">
                                            <div className="main-container">
                                                <div className={"nav-left-section"}>
                                                {this.state.data && <Typography category='heading2' className="role-title" value={roleNameAndPlace} />}
                                                </div>
                                                <div className={"nav-right-section"}>
                                                    <div>
                                                        <div onClick={this.togglePopover.bind(this)}
                                                            data-html={true}
                                                            data-for="PDFDownload"
                                                            data-tip={intl.formatMessage({ 'id': 'pdf_download_tooltip' })}
                                                            className={`ga ga-download pdf-download  margin-left-auto darktooltip ${this.state.showPopover ? 'open' : ''}`} ></div>
                                                        <ReactTooltip className='tooltip' id="PDFDownload" place="bottom" effect="solid" />
                                                        <ClickOutHandler onClickOut={this.onClickOut.bind(this)}>
                                                            <div>
                                                                <PopOver  style={refineStyle}
                                                                    showPopover={this.state.showPopover}
                                                                    downloadPdf={this.downloadPdf.bind(this)}
                                                                    togglePopover={this.togglePopover.bind(this)}
                                                                    />
                                                            </div>
                                                        </ClickOutHandler>
                                                    </div>
                                                    <div className="curr-download-seperator"></div>
                                                    <CurrencyList {...this.state.data}/>
                                                </div>
                                            </div>
                                        </div>
                                    </header>
                                );
                            }}
                        </Sticky>
                    </div>
                    <div className="dashboard-content">
                        <div className="main-container">
                            <div className="row">
                                <div className="col-md-12">
                                    <IntakeViewButton toggleFlyout={this.toggleFlyout} intl={intl} />
                                    <WidgetsList {...this.state.data}  {...this.props} page="Dashboard" />
                                </div>
                            </div>
                        </div>
                    </div>
                    </StickyContainer> }
                    <TransitionGroup className="download-popup">
                        {this.state.downloadArray.map((download, i) => {
                            let bottom = 30 + (70 * ((this.state.downloadArray.length - 1) - i));
                            const pStyle = {
                                bottom: bottom + 'px',
                                position: 'fixed',
                                left: '30px'
                            };
                            return (
                                <CSSTransition key={download.guid} timeout={2000} classNames="popup">
                                    <div style={pStyle}>
                                        <DownloadPopop reportDownloadingStatus={download.status} />
                                    </div>
                                </CSSTransition>)

                        })}
                    </TransitionGroup>
            </div>
        )
    }
}
Dasboard.contextTypes = {
    store: PropTypes.object,
    intl: PropTypes.object.isRequired
}

const mapDispatchToProps = (dispatch) => ({
    doSetDashboardData: data => dispatch(setDashboardData(data)),
    doSetSelected: (val) => dispatch(setSelected(val)),
    doCallCandidateProfiles: (val) => dispatch(candidateProfiles(val)),
})

const mapStateToProps = ({dashboard}) => {
    return {
        sourceList : dashboard.sources
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dasboard);


const dashboardAPI = (roleID) => {
    return viewResultsRole.onViewResults({ roleID }).then(res => {
        const { data: responses } = { ...res.data }
        return responses
    })
};