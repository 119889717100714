import React, { Component } from 'react';

export default class LineEllipsis extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }

    excerptLines = this.excerptLines.bind(this)

    componentDidMount() {
        this.excerptLines();
        window.addEventListener("resize", this.excerptLines);
        window.addEventListener("scroll", this.excerptLines);
        if(this.props.container){
            let containerElement = document.querySelector(this.props.container);
            if(containerElement){
                containerElement.addEventListener("scroll", this.excerptLines);
            }
        }
    }

    componentWillReceiveProps() {
        this.excerptLines();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.excerptLines);
        window.removeEventListener("scroll", this.excerptLines);
        if(this.props.container){
            let containerElement = document.querySelector(this.props.container);
            if(containerElement){
                containerElement.removeEventListener("scroll", this.excerptLines);
            }
        }
    }

    excerptLines() {
        let linesNo = this.props.linesNo;
        let lineHeight, element;
        if (this.myRef && this.myRef.current) {
            element = this.myRef.current;
        }
        element.innerText = this.props.text;
        let originalHeight = element.clientHeight == 0 ?  (element.offsetHeight - 5) : element.clientHeight
        let targetHeight;
        let start = 0;
        let end = this.props.text.length - 1;
        let middle;
        element.innerText = 'w';
        lineHeight = element.clientHeight == 0 ?  element.offsetHeight : element.clientHeight;
        targetHeight = lineHeight * linesNo;
        element.className = this.props.className;
        element.removeAttribute("data-tooltip");
        if (originalHeight <= targetHeight) {
            element.innerText = this.props.text;
            return false;
        }

        let initialFontweight = element.style.fontWeight;
        element.style.fontWeight = 500;

        while (start <= end) {
            middle = Math.floor((start + end) / 2);
            element.innerText = this.props.text.slice(0, middle);

            if (element.clientHeight <= targetHeight) {
                start = middle + 1;
            } else {
                end = middle - 1;
            }
        }
        //element.setAttribute("title", this.props.text);
        element.setAttribute("data-tooltip", this.props.text);
        element.classList.add("tooltip-bottom");
        window.setTimeout(function(){
            element.className = this.props.className;
            let position = this.getOffset(element);
            element.pseudoStyle("after", "top", position.top + element.clientHeight + "px");
            element.pseudoStyle("after", "left", position.left + "px");
            element.pseudoStyle("after", "width", element.offsetWidth + "px");
        }.bind(this),100);
        element.innerText = this.props.text.slice(0, middle - 3) + '...';
        element.style.fontWeight = initialFontweight;
        return true;
    }

    getOffset(el) {
        const rect = el.getBoundingClientRect();
        return {
          left: rect.left,
          top: rect.top
        };
      }


    handleClick() {
        if (typeof (this.props.onClick) === "function") {
            this.props.onClick();
        }
    }


    render() {
        return <span onClick={this.handleClick.bind(this)}  ref={this.myRef}>
        </span>;
    }
}

LineEllipsis.defaultProps = {
    linesNo: 2
}


var UID = {
    _current: 0,
    getNew: function () {
        this._current++;
        return this._current;
    }
};

HTMLElement.prototype.pseudoStyle = function (element, prop, value) {
    var _this = this;
    var _sheetId = "pseudoStyles";
    var _head = document.head || document.getElementsByTagName('head')[0];
    var _sheet = document.getElementById(_sheetId) || document.createElement('style');
    _sheet.id = _sheetId;
    var className = "pseudoStyle" + UID.getNew();

    _this.className += " " + className;

    _sheet.innerHTML += " ." + className + ":" + element + "{" + prop + ":" + value + "}";
    _head.appendChild(_sheet);
    return this;
};
