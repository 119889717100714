// React Imports
import React from "react";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import './styles.css'


const  getTotal = (data) =>{
    let counts =  (Object.keys(data).map(function (key) {
        return  data[key]
    }))
    return counts.reduce(function(acc, val) { return acc + val; })
}

const buildOptions = function(props) {

    const defaultColors = [
        "rgba(0, 154, 215, 1)",
        "rgba(0, 154, 215, 0.6)",
        "rgba(0, 154, 215, 0.4)",
        "rgba(0, 154, 215, 0.2)",
        "rgba(0, 154, 215, 0.1)"
    ];

    let colors = props.colors ? props.colors : defaultColors;

    const sortedData =  Object.keys(props.data).sort((a,b) => {
        return (props.data[a] > props.data[b]) ? -1 : 1
    })

    const data = sortedData.filter(function(key) {
        if (props.mapping[key])
            return (props.mapping[key]);
    }).map(function(key, index) {
        return {
            y: props.data[key],
            color: colors[index],
            colorByPoint: true,
            name: props.mapping[key],
            id: index + 1,
            selected: false,
            sliced: false
        }
    })

    let options = {
        chart : {
            floating: true,
            plotBackgroundColor : null,
            plotBorderWidth : null,
            plotShadow : false,
            type: 'pie',
            height:190,
            width:390,
            style:{
                fontFamily: "'Graphik Web',Arial !important"
            }

        },
        title:{
            text:''
        },
        subTitle:{
            text:''
        },
        legend : {
            useHTML: true,
            symbolHeight: 12,
            symbolWidth: 12,
            symbolRadius: 0,
            layout: 'square',
            align: 'left',
            verticalAlign: 'middle',
            floating: true,
            itemStyle: {
                fontFamily: "Graphik Web, Arial"
            },
            x: 0,
            y: 5,
            width:220,
            labelFormatter: function () {
                //let percent  = (this.y / total) * 100;
                return ("<div class='row legende-piechart-section'><div class='col-xs-8 no-padding'>"  + this.name + "</div><div class='col-xs-4 no-padding pull-left'>" + this.y + "%</div></div>");
            }
        },
        credits: {
            enabled: false
        },
        tooltip : {
            borderWidth: 0,
            backgroundColor: "rgba(255,255,255,0)",
            borderColor : '#c2c2c2',
            borderRadius: 0,
            margin: 0,
            padding: 0,
            shadow: false,
            useHTML: true,
            hideDelay:0,
            backgroundColor: "rgba(255,255,255,1)",
            formatter: function () {
                return '<div>' + this.point.name + '<br />' + this.y + ' %</div>';
            }
        },
        plotOptions : {
            point: {
                center: ["50%", "50%"],
                events: {
                    legendItemClick: function () {
                        return false;
                    }
                }
            },
            allowPointSelect: true,
            series : {
                marker: {
                    enabled: true
                },
                point:{
                    events: {
                        legendItemClick: function () {
                            return false;
                        }
                    }},
                shadow : false,
                states:{
                    hover:{
                        animation:{
                            duration:50
                        },
                        brightness:0,
                        enabled:true,
                        halo:{
                            opacity:1,
                            size:0,
                        },
                        lineWidthPlus:1
                    },
                    inactive:{
                        opacity:1
                    },
                    normal:{
                        animation:false
                    },
                },
                events: {
                    afterAnimate: function() {
                        let chart = this.chart;
                        let legend = chart.legend;
                        let tooltip = this.chart.tooltip;
                        for (let item of legend.allItems) {
                            let data = item.series.data[item.index];
                            item.legendItem.on('mouseover', function (e) {
                                tooltip.refresh(data);
                            }).on('mouseout', function (e) {
                                tooltip.hide();
                            });
                        }
                    }
                },
            },
            pie : {
                size: props.isReport ? 100 : null,
                center: props.isReport ? [230, 65] : [270, 65],
                borderWidth : 1,
                allowPointSelect : false,
                cursor : 'pointer',
                showInLegend : true,
                dataLabels : {
                    enabled : false,
                    color : '#333333',
                    distance : 10,
                    connectorWidth : 1,
                    connectorColor : '#333333',
                    formatter : function() {
                        return Math.round(this.percentage * Math.pow(10, 2)) / Math.pow(10, 2) + ' %';
                    }
                }
            }
        },
        series: [{ data: data }]
    };
    return options;
};

const PieChart = (props) => {
    return (<div className={props.className}>
                <HighchartsReact highcharts={Highcharts} options={buildOptions(props)} {...props} />
            </div>)
};

export default PieChart;
