import React from 'react';
import {connect} from 'react-redux'
import {CurrencyDropDown} from 'talentneuron---ui-components-library';
import {getCurrencylist, setSelected} from '../../actions/currency';

class CurrencyList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            selected:this.props.currency
        };

        this.changeHandler = this.changeHandler.bind(this);
    }

    componentDidMount() {
        this.props.doGetCurrencylist();
    }

    changeHandler(item) {
            this.setState(prevState =>({
                ...prevState,
                selected:item
            }));

        if(this.props.selected)
            this.props.doSetSelected(item);
    }


    render() {
        let defaultValue = this.state.selected ? `${this.state.selected.symbol} ${this.state.selected.code}` : "$ USD"
        return (
            <div className="dropdownControl currency">
                {
                    this.props.allCurrency && <CurrencyDropDown  placeHolderValue={defaultValue} {...this.props} changeHandler={this.changeHandler}/>

                }
            </div>
        )
    }
}

const mapStateToProps = function (state) {
    return {
        allCurrency: state.currency.all,
        userDetails: state.auth.userObject.userDetails,
        selected: state.currency.selected,
    };
};

const mapDispatchToProps = dispatch => ({
    doGetCurrencylist: () => dispatch(getCurrencylist()),
    doSetSelected: (val) => dispatch(setSelected(val)),
});


export default connect(mapStateToProps, mapDispatchToProps)(CurrencyList);