import React from 'react';
import { Link } from 'react-router-dom'
import { FeatureAnnouncement, TalentNeuronLogo, UniversalSearchV2, UtilityBar } from 'tn-components-library'
import { getRecentSearches, getnotificationbydate, recentSearchTracking } from '../../http/search';
import Config from '../../profiles';
import { getPersonaResponsibilities } from "../../http/userProfile";
import { handleEndSession, removeUserFromCache } from '../../http/viewAs'
import UserProfile from "../../container/UserProfile";
import SideNav from './SideNav';
import { UniversalSearchConfig } from '../../utils';
import http from '../../http/http';


const HeaderContent = ({ isfeatureEnabled, featureAnnouncement, config, isMimicClient }) => {
	return (
		<div className={`header-wrapper ${isMimicClient ? 'header-with-utility' : ''}`}>
			<div className='header-content'>
				<a href={Config.getProductHomeURL} target='_self'>
					<TalentNeuronLogo width='150' height='22' />
				</a>
			</div>
			<UniversalSearchV2 {...config} />
			{
				isfeatureEnabled && featureAnnouncement &&
				<FeatureAnnouncement
					id={featureAnnouncement.id}
					tag={featureAnnouncement.titletag}
					title={featureAnnouncement.title}
					linkLabel={featureAnnouncement.ctalabel}
					featureLink={featureAnnouncement.ctalink}
					endDate={featureAnnouncement.enddate}
					message={featureAnnouncement.message}
				/>
			}
		</div>
	)
}

class Header extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			userResponsabilitiesQuestions: [],
			userPersonaQuestions: [],
			featureAnnouncement: null,
			insights: [],
			isfeatureEnabled: false,
			topInsights: null,
			recentSearchData: []
		}
		this.getPersonaResponsibilities = this.getPersonaResponsibilities.bind(this);
		this.getNotificationData = this.getNotificationData.bind(this);
		this.getInsights = this.getInsights.bind(this);
		this.getRecentSearchData = this.getRecentSearchData.bind(this);
		this.onWidgetCallBack = this.onWidgetCallBack.bind(this);
		this.productPermission = {
			Recruit: window.customrole.UserContext.tnr2xenabled,
			Plan: window.customrole.UserContext.tnp2xenabled,
			Custom_Roles: true,
			Admin: window.customrole.UserContext.adminenabled,
			Datafeeds: window.customrole.UserContext.dataFeedEnabled,
		};
	}
	async componentDidMount() {
		if (window.customrole.UserContext.personaID === 0 || !window.customrole.UserContext.responsibilityIdsCSV) {
			this.getPersonaResponsibilities();
		}
		await this.getNotificationData();
		await this.getInsights();
		await this.getRecentSearchData();
	}

	getPersonaResponsibilities() {
		getPersonaResponsibilities().then((resp) => {
			resp && resp.status === 200 && this.setState(prevState => {
				let { userResponsabilities, userPersona } = { ...prevState }
				userPersona = resp.data.data.persona;
				userResponsabilities = resp.data.data.responsibilities;
				//Push placeholder for Custom input
				userPersona && userPersona.length && userPersona.push({ id: 0, name: "", type: "input" });
				return {
					userResponsabilitiesQuestions: userResponsabilities,
					userPersonaQuestions: userPersona
				}
			})

		}).catch((error) => {
			console.log("error while getting  Persona and Responsabilities");
		});
	}

	async getNotificationData() {
		const date = new Date();
		let currentDate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
		await getnotificationbydate(currentDate).then((responses) => {
			const data = responses.data
			if (data.notifications.length > 0 && !data.isError) {
				const announcements = data.notifications
				const featureAnnouncements = announcements.filter((announcement) => {
					let tag = announcement.platformtag
					if (tag) {
						tag = tag.replace(/\s+/g, ' ')?.trim()?.replace(" ", "_")
					}
					if (tag == null || this.productPermission[tag]) {
						return true
					}
					return false
				}).sort((prev, next) => (prev.id > next.id ? -1 : 1))
				this.setState({
					isfeatureEnabled: featureAnnouncements.length > 0,
					featureAnnouncement: featureAnnouncements.length > 0 ?  featureAnnouncements[0] : {}
				})
			}
		}).catch((error) => {
			console.log("error while getting  major announcements ", error);
		});
	}

	async getInsights() {
		const insightURL = Config.getAdminServiceURL + '/v1/cms/gettninsights'
		const insightsOptions = {
			categories: 'All',
			pageno: 1,
			totalrecords: 100,
			fromdate: '2020-01-01',
			todate: '2099-12-31',
			searchtext: '',
		}
		await http.post(insightURL, insightsOptions).then((response) => {
			const insightResponse = response.data
			if (!insightResponse.isError && insightResponse.data && insightResponse.data.insightslist?.length) {
				const insightList = insightResponse.data.insightslist || [];
				const insights = insightList.slice(0, 3);
				this.setState({
					insights: insightList,
					topInsights: insights
				});
			}
		})
		.catch(err => {
			console.log('err', err);
		})
	}

	async getRecentSearchData() {
		await getRecentSearches().then((data = []) => {
			const topRecentSearches = data
			this.setState({
				recentSearchData: topRecentSearches
			})
		})
	}

	async onWidgetCallBack(widgetDetails) {
		await recentSearchTracking(widgetDetails)
	}

	render() {
		const utilityBarDetails = {
			clientName: window.customrole.UserContext.clientname
		}
		const config = { ...UniversalSearchConfig(), insights: this.state.insights, recentSearches: this.state.recentSearchData}
		const { featureAnnouncement = {}, isfeatureEnabled, insights, recentSearchData = [] } = this.state
		return (
			<div>
				<div id="flyouthelp"></div>


				<header className="navbar" id="navbar" style={{ border: "none" }}>
					{window.customrole.UserContext.mimicclient &&
						<UtilityBar utilityBarDetails={utilityBarDetails} handleEndSession={handleEndSession} />
					}
					<SideNav insights={this.state.insights} />
					<HeaderContent
						isfeatureEnabled={isfeatureEnabled}
						featureAnnouncement={featureAnnouncement}
						config={{
							...config,
							recentSearches: recentSearchData,
							insights,
							onClickCallback: (widgetDetails) => this.onWidgetCallBack(widgetDetails)
						}}
						isMimicClient={window.customrole.UserContext.mimicclient}
					/>
				</header>
				{
					((this.state.userPersonaQuestions && this.state.userPersonaQuestions.length)
						|| (this.state.userResponsabilitiesQuestions && this.state.userResponsabilitiesQuestions.length))

						? <UserProfile userPersonaQuestions={this.state.userPersonaQuestions} userResponsabilitiesQuestions={this.state.userResponsabilitiesQuestions} />
						: null
				}
			</div>
		)
	}
}

export default Header;
