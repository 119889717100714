import React  from "react";
import Widget from "../../component/Widget";
import PropTypes from 'prop-types';
import './style.css';
import SolidGuage from '../../component/SolidGuage';
import TextColumns from "../../component/TextColumns"

class Skills extends React.PureComponent {
    constructor(props){
        super(props);
        this.getTopSkillsGuageInstance = this.getTopSkillsGuageInstance.bind(this);
        this.renderExpandedView = this.renderExpandedView.bind(this);
    }

    getTopSkillsGuageInstance(containerId,skillValue,skillPercent,skillLabel){
        return(
            <div className = "guageContainer col-xs-2" key={containerId} >
                <SolidGuage isReport={this.props.isReport} skillValue ={skillValue} skillPercent={skillPercent} container= {containerId} />
                <div className="skillLabel">{skillLabel}</div>
            </div>
        )
    }

    renderExpandedView(){
        return <div className="skillsExpandedBlock row">
            <div className="col-md-3"></div>
            <div className="col-md-6">
                <TextColumns data={this.props.data.searchterm.slice(5)} noOfColumns={2}  />
            </div>
            <div className="col-md-3"></div>
        </div>
    }

    render() {
        let intmsg = this.context.intl;
        let showMore = intmsg.formatMessage({ id: "show_more_skill" }),
        showLess = intmsg.formatMessage({ id: "show_fewer_skill" });
        return (
            <Widget  {...this.props} renderExpandedView={this.renderExpandedView} showMoreText={showMore} showLessText={showLess}>
                <div className="row">
                    <div className="col-md-12 guageOuterContainer">
                            <div className="col-xs-1"></div>
                            {
                                (this.props.data && this.props.data.breakdown.length) && this.props.data.breakdown.map((skill,index)=>{
                                    return this.getTopSkillsGuageInstance(`solidGuage${index}`,skill.value,skill.percent,skill.name);
                                })
                            }
                            <div className="col-xs-1"></div>
                    </div>
                </div>
            </Widget>
        );
    }
}

Skills.contextTypes = {
    intl: PropTypes.object.isRequired
}

export default Skills;
