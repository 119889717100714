import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'tn-components-library';
import './styles.css';

export default class CustomNoData extends Component {
  render() {
    let intl = this.context.intl;
    return (
      <div className="text-center">
        <Typography category='heading3' className="margin-top-30 margin-bottom-20 no-bold" value={intl.formatMessage({ id: "no_data_3" })} />
        <div className="support-mail ga ga-envelope"></div>
        <div className="help-desk margin-top-10">
          <a target="_blank" href="https://talentneuron.my.site.com/Support/s/contactsupport">
            {intl.formatMessage({ id: "help_desk" })}
          </a>
        </div>
        <div className="submit-ticket margin-top-5">{intl.formatMessage({ id: "ticket" })}</div>
      </div>
    );
  }
}

CustomNoData.contextTypes = {
  intl: PropTypes.object.isRequired
}

CustomNoData.defaultProps = {
  queryNoMatch: false
}