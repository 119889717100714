import React from 'react'
import {connect} from 'react-redux'
import Widget from "../../component/Widget";
import  {formatNumber} from '../../utils';
import {HxCodeToDangerousDOMElelemt} from '../../utils';
import './styles.css'



const  SalaryTooltip = (props) =>{
    let danger_dom = HxCodeToDangerousDOMElelemt(props.currency.symbol+ props.values[props.val]);

        return (
            <div className={"market_arrow_box " + props.classname}>
                <div className={"salary-number"}>{danger_dom}</div>
                <div className={"label"}>{props.label}</div>
            </div>
        );
};
const values = (rate, data)=>{
    let  min =  formatNumber(data.min * rate);
    let  max =  formatNumber(data.max * rate)

    return {min:min, max:max}
};
const Salary = (props) => {


    let selected = props.selected  || props.defaultCurrency;
    if(props.isReport){
        selected = props.defaultCurrency
    }
    let salary =  selected && props.data ? values(selected.rate, props.data) : null;
    const style = {
        width: "68%",
        height: "15px",
        backgroundColor: "#c0c0c0",
        margin:"auto",
        marginTop:"60px"
    };
    return (

        <div className={"salary-widget"}>
            <Widget  {...props}>
                <div id={"salary-section"} style={style}>
                    <div style={{width: "15%", height: "15px", float: "left"}}></div>
                    <div style={{width: "4%", height: "15px", backgroundColor: "#ff540a", float: "left"}}>
                        <SalaryTooltip currency={selected} {... props} values={salary} classname={"LeftSalaryToolTip"} label={"Low"} val={"min"}/>
                    </div>

                    <div style={{width: "62%", height: "15px", backgroundColor: "#002856", float: "left"}}></div>
                    <div style={{width: "4%", height: "15px", backgroundColor: "#ff540a", float: "left"}}>
                        <SalaryTooltip currency={selected} {... props} values={salary} classname={"RightSalaryToolTip"} label={"High"} val={"max"}/>
                    </div>

                    <div style={{width: "15%", height: "15px", float: "left"}}></div>
                </div>

            </Widget>
        </div>
    )
};


const mapStateToProps = (state => {
    return{
        selected: state.currency.selected
    }
});


export default connect(mapStateToProps, null)(Salary)
