import {createAction} from 'redux-actions';
import axios from 'axios';
import http from "../http/http";
import Config from "../profiles";

export const setDashboardData = createAction("SET_DASHBOARD_DATA");
export const setCandidateProfilesData = createAction("SET_CANDIDATE_PROFILES_DATA");
export const setSourceLoading = createAction("SET_SOURCE_LOADING");


export const candidateProfiles = (roleID) => {

    return (dispatch) => {
        return new Promise(resolve => {

            dispatch(setSourceLoading(true))
            let siteList = [];

            http.get(Config.serviceURL + "/v1/cr/jobrole/candidateprofiles?customRoleId=" + roleID)
                .then(response => {
                    if (response.data.isError) {
                        dispatch(setSourceLoading(false))
                        return resolve(siteList);
                    }

                    Promise.all(response.data.data.map(site => {

                        return axios.get(site.googleapiurl)
                            .then(googleResp => {
                                if (googleResp.data.searchInformation.totalResults !== '0')
                                    return site;
                            })
                    }))
                        .then((response) => {

                            siteList = response.filter(el => el !== undefined);
                            dispatch(setCandidateProfilesData(siteList));
                            dispatch(setSourceLoading(false))
                            resolve(siteList);
                        });
                });
        });
    }
};


