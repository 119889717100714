import http from "./http";
import Config from "../profiles";

const onViewResults = ({ roleID }) => {
    return http.get(
        Config.serviceURL + "/v1/cr/jobrole/dashboarddata?customRoleId="+roleID
    );
};


export default { onViewResults };