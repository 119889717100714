import React from 'react'
import PropTypes from 'prop-types';
import WidgetsList from '../widgets/widgetsList';
import {setDashboardData} from '../../actions/dashboard'
import {connect} from 'react-redux'
import {setSelected, getCurrencylist} from "../../actions/currency";
import viewResultsRole from "../../http/dashboard";
import { getParameterByName } from '../../utils';
import PDFPage from '../../component/PDFPage'
import { getRoleDetail } from "./../../http/search"
import PDFCoverPage from '../PDFCoverPage';
import './style.css'


class Report extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            data: null,
            currency : null
        };
    }
    loadData(){
        let roleID = Number(getParameterByName("role"))
        let currency = decodeURIComponent(getParameterByName("cur"))
        let currencyName = decodeURIComponent(getParameterByName("name"))
        Promise.all([
            getRoleDetail("roleidx=" + roleID),
            viewResultsRole.onViewResults({ roleID }).then(res => {
                const { data: responses } = { ...res.data }
                return responses
            }),
            this.props.doGetCurrencylist()
        ]).then(values => {
            let allCurrency = [...values[2].othercurrency, ...values[2].topcurrency]
            let currObj = allCurrency.filter(item => item.code == currency)[0]
            if(currencyName.length) currObj.name = currencyName;
            let responses = {...values[0].data[0],...values[1]};
            if (responses) {
                this.setState({ data: responses, currency : currObj }, ()=>{
                    this.props.doSetDashboardData(this.state.data)
                })
            } else {
                return -1
            }
        }).catch(error => {
            console.log(error);
        });
    }

    componentDidMount() {
        this.loadData()
    }
    render() {
        let title = getParameterByName("title")
        let description = decodeURIComponent(getParameterByName("description"))
        return (
            <div>
                <PDFPage coverPage={true} appName="Custom Roles" title={title} description={description}>
                    <PDFCoverPage {...this.state.data}  currency={this.state.currency} isReport={true} intl={this.context.intl} /> 
                </PDFPage>
                <PDFPage appName="Custom Roles" title={title} pageNumber={2}>
                    <WidgetsList {...this.state.data}  currency={this.state.currency} page="ReportPage1" />
                </PDFPage>
                <PDFPage appName="Custom Roles" title={title} pageNumber={3}>
                    <WidgetsList {...this.state.data} page="ReportPage2" />
                </PDFPage>
            </div>
        )
    }
}


const mapDispatchToProps = (dispatch) => ({
    doGetCurrencylist: () => dispatch(getCurrencylist()),
    doSetDashboardData: data => dispatch(setDashboardData(data)),
    doSetSelected: (val) => dispatch(setSelected(val)),
})

const mapStateToProps = function (state) {
    return {
        userDetails: state.auth.userObject.userDetails,
    };
};

Report.contextTypes = {
    intl: PropTypes.object.isRequired
}


export default connect(mapStateToProps, mapDispatchToProps)(Report);