import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'tn-components-library';
import './styles.css';
import CustomNoData from "./index"

export default class NoMatch extends Component {
    render() {
        let intl = this.context.intl;
        return (
            <div className="margin-top-40 text-center">
                <Typography category='heading3' className="no-margin" value={intl.formatMessage({ id: "no_data_match1" })} />
                <Typography category='heading3' className="no-margin" value={intl.formatMessage({ id: "no_data_match2" })} />
                <CustomNoData/>
            </div>

        );
    }
}

NoMatch.contextTypes = {
    intl: PropTypes.object.isRequired
}

NoMatch.defaultProps = {
    queryNoMatch: false
}